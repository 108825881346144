<template>
	<div class="position-relative puerta" :style="{ backgroundColor: backgroundColor }">
		<div class="position-absolute number">{{ number }}</div>
		<div class="position-absolute letter">
			<img :src="`${logo}`" alt="" width="22" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PuertaVending',
		props: ['id', 'number', 'logo', 'backgroundColor', 'state'],
		data() {
			return {
				//
			};
		},
		methods: {
			//
		},
	};
</script>

<style>
	.puerta {
		height: 50px;
		width: 50px;
		border-radius: 8px;
	}
	.number {
		height: 20px;
		width: 20px;
		background-color: white;
		color: #003d52;
		top: -10px;
		left: 15px;
		text-align: center;
		font-size: 14px;
		font-weight: 900;
	}
	.letter {
		height: 30px;
		width: 30px;
		background-color: transparent;
		color: #003d52;
		bottom: 5px;
		left: 10px;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
	}
</style>
