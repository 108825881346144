<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="1" />
        <b-col lg="9">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
							<div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
							Dashboard /
							<div class="ico-sidebar ico_user inlineBlock iconVending mr-0"></div>
							Clientes
						</card>
            <card>
                <h3 class="mb20x">Listado de clientes</h3>
                <b-row>
                  <b-col v-if="gestoria" cols="12">
                    <base-input label="Selecciona cliente">
                      <el-select filterable multiple>
                        <el-option v-for="option in selectTienda"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </b-col>
                <b-col cols="6">
                  <!-- <input type="file" id="testFile">
                  <button @click="envioInfo()">Envio test</button> -->
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
                <b-col cols="6" class="text-right">
                    <div>
                      
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="W20x"><img src="../../../public/export-file.svg" class="mxW25x" alt=""></div>
                      </template>
                      <b-dropdown-item>Exportar PDF</b-dropdown-item>
                      <b-dropdown-item>Exportar CSV</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="4">
                    <base-input v-model="emisor" label="Nombre" prepend-icon="fas fa-user" placeholder="Nombre"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input v-model="emisor" label="Apellidos" prepend-icon="fas fa-user" placeholder="Apellidos"></base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input v-model="emisor" label="Email" prepend-icon="fas fa-user" placeholder="Email"></base-input>
                  </b-col>
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="">Filtrar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(coste)="row">
                  <b>{{ row.item.coste }}€</b>
                </template>
                <template #cell(emisor)="row">
                  {{ row.item.emisor }}
                  <!-- <div v-if="row.item.metodo !== undefined" :class="row.item.metodo === 'Tarjeta' ? 'metodo' : 'metodo2'">
                    {{ row.item.metodo }}
                  </div>
                   <b-button type="primary" size="sm" @click="descarga(row.item)" class="mr-5 concepto2 p5x"> <img src="../../../public/descargar.png" class="mxW15x" alt=""> </b-button>
                   <b-button type="primary" size="sm" @click="borrar(row.item)" class="mr-2 concepto2 p5x"> <img src="../../../public/borrar.png" class="mxW15x" alt=""> </b-button> -->
                </template>
                <template #cell(acciones)="row">
                  <div v-if="row.item.metodo !== undefined" :class="row.item.metodo === 'Tarjeta' ? 'metodo' : 'metodo2'">
                    {{ row.item.metodo }}
                  </div>
                   <b-button type="primary" size="sm" @click="descarga(row.item)" class="mr-5 concepto2 p5x"> <img src="../../../public/descargar.png" class="mxW15x" alt=""> </b-button>
                   <b-button type="primary" size="sm" @click="borrar(row.item)" class="mr-2 concepto2 p5x"> <img src="../../../public/borrar.png" class="mxW15x" alt=""> </b-button>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  // import flatPicker from "vue-flatpickr-component";
  // import "flatpickr/dist/flatpickr.css";
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      // flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'nombre', sortable: false },
          { key: 'apellidos', sortable: false },
          // { key: 'hora', sortable: false },
          { key: 'correo', sortable: false },
          { key: 'licencia', sortable: false },
          { key: 'pago', sortable: false },
          { key: 'fecha', sortable: false }
        ],
        selectTienda: [
          { label: 'tienda 1', value: 'tienda 1' },
          { label: 'tienda 2',value: 'tienda 2' },
        ],
        items: [],
        busqueda: false,
        desde: '',
        hasta: '',
        gestoria: false
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },
    async mounted () {
      const res = await api.getExpenses(this.authUser)
      res.forEach(element => {
        element.fecha = element.date
        element.coste = element.total
        element.emisor = element.issuer
        element.descripcion = element.concept
      })
      this.items = res
      window.location.href.includes('?mod=list') ? this.gestoria = true : this.gestoria = false
    },
    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),
      async envioInfo () {
        var input = document.querySelector('input[type="file"]')
        // console.log(input)
        // console.log(input.files[0])
        // var reader = new FileReader(),
        // file = document.querySelector('input[type="file"]')
        // var data = reader.result,
        //     base64 = data.replace(/^[^,]*,/, '')

        // const obj = {
        //   hello: "world"
        // };
        // const json = JSON.stringify(obj);
        // const blob = new Blob([json], {
        //   type: 'application/json'
        // });
        // const data = new FormData();
        // data.append("document", blob);
        // axios({
        //   method: 'post',
        //   url: '/sample',
        //   data: data,
        // })
            const datos = {
                concept: 'fot',
                issuer: 'abc',
                total: 123.05,
                date: '2022-05-20'
            }
            var data = new FormData()
        var f = document.querySelector('input[type="file"]') // FileList object
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function(theFile) {
            return function(e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              // document.getElementById('base64').value = base64String;
data.append('file', base64String)
            };
          })(f);
          // Read in the image file as a data URL.
          // reader.readAsBinaryString(f);
        

        // data.append('file', JSON.stringify(input.files[0]))
        data.append('expenseRequest', JSON.stringify(datos))
        await api.setExpenses(this.authUser, data)

      },
      resetFilters() {
        this.fecha = null
        this.fecha2 = null
        // this.items = JSON.parse(localStorage.getItem('facturas') || "[]")
      },

      async descarga (item) {
        const res = await api.downloadFactura(this.authUser, item.id)
        const tipo = this.obtenerTipoArchivo(res)
        this.descargarArchivo(res, tipo, 'archivo')
        // const contenidoBinario = atob(res);
        // // Crea un objeto Blob a partir de la cadena binaria
        // const blob = new Blob([contenidoBinario], { type: tipo });
        // // Crea un objeto URL a partir del objeto Blob
        // const urlArchivo = URL.createObjectURL(blob);
        // // Crea un enlace HTML y configura sus atributos
        // const enlaceDescarga = document.createElement("a");
        // enlaceDescarga.setAttribute("href", urlArchivo);
        // enlaceDescarga.setAttribute("download", 'factura_' + item.id);
        // // Simula un clic en el enlace para iniciar la descarga
        // enlaceDescarga.click();
      },

       descargarArchivo(base64Data, tipoArchivo, nombreArchivo) {
          // Extraer la extensión del tipo de archivo
          var extension = tipoArchivo.split('/')[1];

          // Añadir la extensión al nombre de archivo
          nombreArchivo += '.' + extension;

          // Decodificar la cadena Base64
          var byteCharacters = atob(base64Data);

          // Crear un array que represente los bytes del archivo
          var byteArrays = [];
          for (var i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }

          // Crear un objeto Blob con los bytes del archivo
          var blob = new Blob([new Uint8Array(byteArrays)], { type: tipoArchivo });

          // Crear una URL temporal para el archivo
          var urlArchivo = URL.createObjectURL(blob);

          // Crear un enlace y establecer los atributos de descarga
          var link = document.createElement('a');
          link.href = urlArchivo;
          link.download = nombreArchivo;

          // Simular el clic en el enlace para iniciar la descarga
          link.click();

          // Liberar la URL temporal
          URL.revokeObjectURL(urlArchivo);
        },


      obtenerTipoArchivo (base64Data) {
        var dataStart = base64Data.substring(0, 20); // Obtener los primeros bytes de la cadena Base64
        var byteCharacters = atob(dataStart); // Decodificar los primeros bytes

        var tipoArchivo = "application/octet-stream"; // Tipo de archivo predeterminado

        // Detectar el tipo de archivo basado en las cabeceras
        if (byteCharacters.startsWith("\x89\x50\x4E\x47")) {
          tipoArchivo = "image/png";
        } else if (byteCharacters.startsWith("\xFF\xD8\xFF")) {
          tipoArchivo = "image/jpeg";
        } else if (byteCharacters.startsWith("GIF")) {
          tipoArchivo = "image/gif";
        } else if (byteCharacters.startsWith("BM")) {
          tipoArchivo = "image/bmp";
        } else if (byteCharacters.startsWith("%PDF")) {
          tipoArchivo = "application/pdf";
        } else if (byteCharacters.startsWith("IV")) {
          tipoArchivo = "video/x-ivf";
        }

        return tipoArchivo;
      },

      async borrar (item) {
        await api.deleteFactura(this.authUser, item.id)
        if (this.desde !== '') {
         this.bsqFilter() 
        } else {
          const res = await api.getExpenses(this.authUser)
          res.forEach(element => {
            element.fecha = element.date
            element.coste = element.total
            element.descripcion = element.concept
          })
          this.items = res
        }
      },

      async bsqFilter () {
        const res = await api.getExpenses(this.authUser, this.desde, this.hasta)
        // let listado
        res.forEach(element => {
          // let criba = false
          // if (this.cliente !== '' && this.cliente !== item.nombre) {
          //   criba = true
          // }
          element.fecha = element.date
          element.coste = element.total
          element.descripcion = element.concept
        })
        
        this.items = res
      },

      filtro() {
        // let splitDesde = null
        // let splitHasta = null
        // if (this.fecha !== null) { splitDesde = this.fecha.split('-') }
        // if (this.fecha2 !== null) { splitHasta = this.fecha2.split('-') }
        // let arrayFiltro = []
        // this.items = JSON.parse(localStorage.getItem('facturas') || "[]")
        // this.items.map(item => {
        //   let ok1 = false
        //   let ok2 = false
        //   if (splitDesde !== null) {
        //     const splitFecha = item.fecha.split('-')
        //     if (parseInt(splitFecha[0]) >= parseInt(splitDesde[0]) && parseInt(splitFecha[1]) >= parseInt(splitDesde[1]) && parseInt(splitFecha[2]) >= parseInt(splitDesde[2])) {
        //       ok1 = true
        //     }
        //   } else {
        //     ok1 = true
        //   } 
        //   if (splitHasta !== null) {
        //     if (parseInt(splitFecha[0]) <= parseInt(splitHasta[0]) && parseInt(splitFecha[1]) <= parseInt(splitHasta[1]) && parseInt(splitFecha[2]) <= parseInt(splitHasta[2])) {
        //       ok2 = true
        //     }
        //   } else {
        //     ok2 = true
        //   }
        //   if (ok1 == true && ok2 == true) { arrayFiltro.push(item) }
        // })
        // this.items = arrayFiltro
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.mxW15x{
  max-width: 15px;
}
</style>