import { render, staticRenderFns } from "./PieChart.vue?vue&type=template&id=cbbdd748&scoped=true&"
import script from "./PieChart.vue?vue&type=script&lang=js&"
export * from "./PieChart.vue?vue&type=script&lang=js&"
import style0 from "./PieChart.vue?vue&type=style&index=0&id=cbbdd748&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbbdd748",
  null
  
)

export default component.exports