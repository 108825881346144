<template>
  <div>
    <figure class="highcharts-figure">
      <div :id="chartId"></div>
      <p class="highcharts-description">
      </p>
    </figure>
  </div>
</template>
<script>
import * as Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

export default {
  name: 'HighchartsChart',
  props: {
    chartId: {
      type: String,
      required: true,
	  default: 'column'
    },
    chartTitle: {
      type: String,
      default: 'Licencias'
    },
    chartSubtitle: {
      type: String,
      default: ''
    },
    xAxisCategories: {
      type: Array,
      default: () => [
        '01/2023',
        '02/2023',
        '04/2023',
        '05/2023',
        '06/2023'
      ]
    },
    seriesData: {
      type: Array,
      required: true,
	  default: () => [{
        name: 'Personal',
        data: [49.9, 71.5, 106.4, 129.2, 144.0]
    	},
      {
        name: 'Grupo',
        data: [49.9, 71.5, 106.4, 129.2, 144.0]
    	}, {
        name: 'Empresa',
        data: [83.6, 78.8, 98.5, 93.4, 106.0]
		}]
    }
  },
  mounted() {
    const chart = Highcharts.chart(this.chartId, {
      chart: {
        type: 'column'
      },
      title: {
        text: this.chartTitle
      },
      subtitle: {
        text: this.chartSubtitle
      },
      xAxis: {
        categories: this.xAxisCategories,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Licencias'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.seriesData
    });
    chart.reflow(); // Para asegurarse de que se renderice el gráfico correctamente
  }
};
</script>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 1000px;
    /* max-width: 90%; */
    /* width: 90%; */
    margin: 1em auto;
}

#container {
    height: 400px;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

</style>