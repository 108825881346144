<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ico_license inlineBlock iconVending mr-0"></div>
              Licencias
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Licencias</h3>
                  </b-col>  
                  <b-col cols="6" class="text-right">
                    <base-button
                      @click.prevent="addItem"
                      type="primary"
                      native-type="submit"
                      >Nuevo</base-button
                    >
                  </b-col>
                </b-row>   
                <b-row>
                <b-col cols="6">
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
               
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="6">
                      <base-input
                        v-model="filterData.client"
                        label="Cliente"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    <b-col md="6">
                      <base-input
                        v-model="filterData.plan"
                        label="Plan"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                </b-row>
                <b-row>  
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="btnFilter">Filtrar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="filter" type="primary" native-type="submit" class="bgTransparent">Cerrar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">  
                <template #cell(startDate)="row">
                  {{ formatearFecha(row.item.startDate) }}
                </template>  
                <template #cell(show_details)="row">
                  <b-button v-if="row.detailsShowing == false" size="sm" @click="showDetail(row)" class="mr-2">
                    <div class="ico-sidebar ni ni-bold-down inlineBlock iconVending mr-0"></div>
                  </b-button>
                  <b-button v-if="row.detailsShowing == true" size="sm" @click="row.toggleDetails" class="mr-2">
                    <div class="ico-sidebar ni ni-bold-up inlineBlock iconVending mr-0"></div>
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-card>
                    <b-row>  
                      <b-col md="12" class="mb-3">
                        <b v-if="company!=null">Compañía:</b>
                        <b v-if="customer!=null">Cliente:</b>
                      </b-col>
                    </b-row> 
                    <b-row v-if="customer!=null">  
                      <b-col md="5" class="mb-3">
                        Nombre: {{ customer.name }} {{ customer.surnames }}
                      </b-col>
                      <b-col md="7" class="mb-3">
                        Correo: {{ customer.email }}
                      </b-col>
                    </b-row>   
                    <b-row v-if="company!=null">  
                      <b-col md="4" class="mb-3">
                        Nombre: {{ company.companyName }}
                      </b-col>
                      <b-col md="4" class="mb-3">
                        Id: {{ company.identify }}
                      </b-col>
                      <b-col md="4" class="mb-3">
                        Dirección: {{ company.address }} . {{ company.city }}  
                      </b-col>
                    </b-row>  
                    <b-row v-if="company!=null">    
                      <b-col md="4" class="mb-3">
                        Contacto: {{ company.contactPerson }}
                      </b-col>
                      <b-col md="4" class="mb-3">
                        Teléfono: {{ company.telephone }}
                      </b-col>
                      <b-col md="4" class="mb-3">
                        Correo: {{ company.email }}
                      </b-col>
                    </b-row>
                    <b-row>  
                      <b-col md="8" class="mb-3"><br>
                        <b>Listado de clientes:</b>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        <base-button
                          @click.prevent="addDetailItem"
                          type="primary"
                          native-type="submit"
                          >Nuevo</base-button
                        >
                      </b-col>
                    </b-row>   
                    <b-table :items="detailItems[row]" :fields="detailFields" striped responsive="sm">
                      <template #cell(acciones)="row">
                        <b-button variant="secondary" size="sm" @click="confirmDeleteItem(row.item)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button>
                      </template>
                    </b-table>
                  </b-card>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- add customer -->
    <modal :show.sync="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          v-model="plan.nameLicense"
          label="Nombre"
          prepend-icon="fas fa-user"
          placeholder="Nombre"
        ></base-input>
        <base-input
          v-model="plan.games"
          label="Juegos"
          prepend-icon="fas fa-user"
          placeholder="Juegos"
        ></base-input>
        <label class="form-control-label d-block"> Marcas </label>
        <el-select label="Marcas Pelota" v-model="plan.listTpTube" filterable multiple style="width:100%;" class="mb-4">
            <el-option
                v-for="marca in marcas"
                :key="marca.id"
                :label="marca.name"
                :value="marca.id"
            >
            </el-option>
        </el-select>
        <base-input
          v-model="plan.price"
          label="Precio"
          prepend-icon="fas fa-user"
          placeholder="Precio"
        ></base-input>
      </form>

      <template  v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveEvent"
        >
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Cerrar
        </button>
      </template>

    </modal>

    <!-- add customer -->
    <modal :show.sync="showAddDetailModal" modal-classes="modal-secondary">
      <form class="new-event--form" enctype="multipart/form-data">
        <b-tabs content-class="mt-3">           
          <b-tab title="Agregar usuario">
              <base-input
                v-model="newCustomer.name"
                label="Nombre"
                prepend-icon="fas fa-user"
                placeholder="Nombre"
              ></base-input>
              <base-input
                v-model="newCustomer.surnames"
                label="Apellido"
                prepend-icon="fas fa-user"
                placeholder="Apellido"
              ></base-input>
              <base-input
                v-model="newCustomer.email"
                label="Correo"
                prepend-icon="fas fa-user"
                placeholder="Correo"
              ></base-input>
          </b-tab>
          <b-tab title="Agregar un grupo de usuarios">
              <input type="file" accept=".csv"  ref="csvFile">
          </b-tab>
        </b-tabs>      
      </form>

      <template  v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveDetailEvent"
        >
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddDetailModal = false"
        >
          Cerrar
        </button>
      </template>

    </modal>

    <modal :show="showDeleteItem" modal-classes="modal-secondary">
      <div class="flex align-items-center justify-content-center">
        <h3>Confirmar</h3>
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>¿Estas seguro que deseas eliminar el Cliente?</span>
      </div>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deleteDetailItem"
        >
          Sí
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="showDeleteItem=false"
        >
          No
        </button>
      </template>
    </modal>

  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'nameCustomer', label: 'Cliente' },
          { key: 'planDto.name', label: 'Plan' },
          { key: 'tpPaymentLicenseDto.name', label: 'Pago' },
          { key: 'startDate', label: 'Fecha' },
          { key: 'show_details', label: 'Detalle' }
        ],
        detailFields: [
          { key: 'name', label: 'Nombre' },
          { key: 'surnames', label: 'Apellido' },
          { key: 'email', label: 'Correo' },
          { key: 'acciones', label: ' ' }
        ],
        items: [],
        detailItems: [],
        marcas: [],
        busqueda: false,
        filterData: {
          client: '',
          plan: '',
        },

        plan: {},
        customer: null,
        company: null,
        newCustomer: {},
        detailItem: {},
        showAddModal: false,
        showAddDetailModal: false,
        showDeleteItem: false,
        licenseId: null
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.getLicencias()
      this.getMarca()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),
      async showDetail(row)
      {
        this.licenseId = row.item.id;
        row.toggleDetails();
        const resp = await api.showLicencia(this.authUser, this.licenseId)
        this.detailItems[row] = [];
        if (typeof(resp) == 'object') {  
          this.detailItems[row] = resp.customerList
          this.customer = resp.customerDto
          this.company = resp.companyDto
        }  
      },

      async getLicencias()
      {
        const resp = await api.getLicencias(this.authUser)
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.itemsFiltro = resp
        }  
      },

      async getMarca()
      {
        const resp = await api.getMarca(this.authUser)
        this.marcas = resp  
      },

      addDetailItem() {
        this.newCustomer = {
          "licenseId": this.licenseId,
          "name":"",
          "surnames": "",
          "email": ""
        }
        this.showAddDetailModal = true
      },

      addItem() {
        this.plan = {
          nameLicense: "",
          games: "",
          price: "",
          listTpTube: ""
        }
        this.showAddModal = true
      },

      async saveEvent() 
      {
        if (
          this.plan.nameLicense == "" ||
          this.plan.games == "" ||
          this.plan.price == "" ||
          this.plan.listTpTube == ""
        ) 
        {
          SwalMixin('Valores inválidos. Favor corregir', 'error')
          return
        }
        let response = null
        if (this.cliente.id == null) {
          response = await api.setCliente(this.authUser, this.cliente)
        }
        if (this.cliente.id != null)
        {
          response = await api.updateCliente(this.authUser, this.cliente)
        }    
        //console.log(response)
        if (response == undefined || response == null || response.code == null || (response.code != '200' && response.code != '201' ))
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            this.getLicencias()
            return
        }   
        SwalMixin(response.message, 'success')
        this.getLicencias()
        this.showAddModal = false
      },   

      editItem (item) {
        this.cliente = item
        this.showAddModal = true
      },

      confirmDeleteItem (item) {
        this.detailItem = item
        this.showDeleteItem = true
      },

      async saveDetailEvent(event) 
      {
        event.preventDefault();
        const csvFile = this.$refs.csvFile.files[0];
       // console.log(csvFile)
        if (csvFile !== undefined) {
          if (csvFile.type !== 'text/csv' && csvFile.type !== 'application/vnd.ms-excel') {
            SwalMixin('Por favor, selecciona un archivo CSV válido.', 'error')
            return;
          }
          const formData = new FormData();
          formData.append('file', csvFile);
          formData.append('licenseId', this.newCustomer.licenseId);
          await api.setClienteWithFile(this.authUser, formData)
        }  
        if (csvFile === undefined) {
          const errorMesg = this.detailValidacion() 
          if (errorMesg !== '')
          {
            SwalMixin(errorMesg, 'error')
            return
          }
          await api.setCliente(this.authUser, this.newCustomer)
        }  
        SwalMixin('Cliente agregado con éxito', 'success')
        this.getLicencias()
        this.showAddDetailModal = false
      },

      async deleteDetailItem () {
        const response = await api.deleteCliente(this.authUser, this.detailItem.customer_id)
        if (response == undefined || response == null)
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            return
        }  
        this.showDeleteItem = false 
        SwalMixin('Cliente eliminado', 'success')
        await this.getLicencias()
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = false
          if (this.filterData.client !== null && this.filterData.client !== '' && it.nameCustomer !== null) {
            if (it.nameCustomer.toLowerCase().includes(this.filterData.client.toLowerCase())) {
              filtro = true
            }
          }

          if (this.filterData.plan !== null && this.filterData.plan !== '') {
            if (it.planDto.name.toLowerCase().includes(this.filterData.plan.toLowerCase())) {
              filtro = true
            }
          }
         //  console.log(filtro)
          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          name: null,
          surnames: null,
          email: null
        }
        this.getLicencias()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      formatearFecha(fecha) {
        // Crea una instancia de la clase Date con la fecha proporcionada
        const date = new Date(fecha);

        // Obtiene los componentes de la fecha
        const dia = date.getUTCDate();
        const mes = date.getUTCMonth() + 1; // Los meses en JavaScript son base 0, por lo que se suma 1
        const anio = date.getUTCFullYear();

        // Formatea la fecha en el formato deseado (por ejemplo, DD/MM/YYYY)
        const fechaFormateada = `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${anio}`;

        return fechaFormateada;
      },

      detailValidacion () {
        let retorno = ''
        const regMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (this.newCustomer.name === null || this.newCustomer.name === '') { retorno = 'Debes introducir un nombre válido' }
        if (this.newCustomer.surnames === null || this.newCustomer.surnames === '') { retorno = 'Debes introducir apellidos válidos' }
        if (!regMail.test(this.newCustomer.email)) { retorno = 'Debes introducir un email válido' }
        return retorno
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}
  
.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}
</style>