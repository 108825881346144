<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ico_user inlineBlock iconVending mr-0"></div>
              Listado Planes
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Listado de planes</h3>
                  </b-col>  
                  <b-col cols="6" class="text-right">
                    <base-button
                      @click.prevent="addItem"
                      type="primary"
                      class="btnAddCenter"
                      native-type="submit"
                      >Nuevo</base-button
                    >
                  </b-col>
                </b-row>   
                <b-row>
                <b-col cols="6">
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <div>                   
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="W20x"><img src="../../../public/export-file.svg" class="mxW25x" alt=""></div>
                      </template>
                      <b-dropdown-item>Exportar PDF</b-dropdown-item>
                      <b-dropdown-item>Exportar CSV</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="6">
                      <base-input
                        v-model="filterData.name"
                        label="Nombre"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    <b-col md="6">
                      <base-input
                        v-model="filterData.surnames"
                        label="Apellidos"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    <b-col md="6">
                      <base-input
                        v-model="filterData.email"
                        label="Correo"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                </b-row>
                <b-row>  
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="btnFilter">Filtrar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="filter" type="primary" native-type="submit" class="bgTransparent">Cerrar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(name)="row">
                  {{ row.item.name }}
                  <!-- arrows -->
                  <b-row>
                    <b-col>
                      <card v-if="row.item.showDetails === true" class="estado__puertas--stats3">
                        <h5>Marcas</h5>
                        <div>
                          <p v-for="(marca, index) in row.item.tpTubeDto" :key="index" class="stats1 mr-1">
                            <img
                              :src="`/logos/${marca.id}.svg`"
                              width="13"
                              class="mr-2"
                            />
                            <span>{{ marca.name }}</span>
                          </p>
                        </div>
                      </card>
                    </b-col>
                    <b-col>
                      <card v-if="row.item.showDetails === true" class="estado__puertas--stats3">
                        <h5>Características</h5>
                        <div>
                          <p v-for="(marca, index) in row.item.features" :key="index" class="stats1 mr-1">
                            <span>{{ marca }}</span>
                          </p>
                        </div>
                      </card>
                    </b-col>
                  </b-row>
                </template>
                <template #cell(nameCliente)="row">
                  {{ row.item.nameCliente }}
                  <b-button variant="info" size="sm" @click="editItem(row.item)"><img src="../../../public/editar.png" class="mxW15x" title="Editar"></b-button>
                  <b-button variant="secondary" size="sm" @click="confirmDeleteItem(row.item)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button>
                  <span>
                    <a  
                      class="inlineB pointer fRight"
                      style="color: inherit !important;" 
                      @click="row.item.showDetails = true"
                      v-if="!row.item.showDetails"
                    >
                      ▼
                    </a>

                    <a  
                      class="inlineB pointer fRight"
                      style="color: inherit !important;" 
                      @click="row.item.showDetails = false"
                      v-if="row.item.showDetails"
                    >
                      ▲  
                    </a>
                  </span>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- add customer -->
    <modal :show.sync="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          v-model="plan.nameLicense"
          label="Nombre"
          prepend-icon="fas fa-user"
          placeholder="Nombre"
        ></base-input>
        <base-input
          v-model="plan.games"
          label="Juegos"
          prepend-icon="fas fa-user"
          placeholder="Juegos"
        ></base-input>
        <label class="form-control-label d-block"> Tipo de cliente </label>
        <el-select v-model="plan.tpCustomerId" filterable style="width:100%;" class="mb-4">
            <el-option
                v-for="marca in tipoClientes"
                :key="marca.id"
                :label="marca.name"
                :value="marca.id"
            >
            </el-option>
        </el-select>
        <base-input
          v-model="feature"
          label="Caracteristicas"
          prepend-icon="fas fa-user"
          placeholder="Caracteristicas"
        ></base-input> <base-button
                      @click.prevent="addFeature"
                      type="primary"
                      class="btnAddCenter"
                      native-type="submit"
                      >+ característica</base-button
                    >
        <div v-if="plan.features.length !== 0">
          <span style="display:block;" class="mt-2">Listado de caracteristicas</span>
          <span v-for="(feature, index) in plan.features" :key="index" style="display:block;" class="mt-1"> {{ feature }} <b-button variant="secondary" size="sm" @click="borrarFeature(index)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button></span>
        </div>
        <label class="form-control-label d-block"> Marcas </label>
        <el-select label="Marcas Pelota" v-model="plan.listTpTube" filterable multiple style="width:100%;" class="mb-4">
            <el-option
                v-for="marca in marcas"
                :key="marca.id"
                :label="marca.name"
                :value="marca.id"
            >
            </el-option>
        </el-select>
        <base-input
          v-model="plan.price"
          label="Precio"
          prepend-icon="fas fa-user"
          placeholder="Precio"
        ></base-input>
      </form>

      <template  v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add btnAddCenter"
          @click="saveEvent"
        >
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Cerrar
        </button>
      </template>

    </modal>

    <modal :show="showDeleteItem" modal-classes="modal-secondary">
      <div class="flex align-items-center justify-content-center">
        <h3>Confirmar</h3>
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>¿Estas seguro que deseas eliminar el Cliente?</span>
      </div>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deleteItem"
        >
          Sí
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="showDeleteItem=false"
        >
          No
        </button>
      </template>
    </modal>

  </div>
</template>
<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'name', label: 'Nombre' },
          { key: 'games', label: 'Partidos mensuales' },
          { key: 'nameCliente', label: 'Tipos cliente' },
          // { key: ' tpCustomerDto.date', label: 'Fecha' },
          // { key: ' acciones', label: 'acciones' },
          //{ key: 'acciones', label: ' ' }
        ],
        items: [],
        marcas: [],
        tipoClientes: [],
        busqueda: false,
        filterData: {
          name: null,
          surnames: null,
          email: null
        },

        plan: {
          nameLicense: "",
          games: "",
          features: "",
          price: "",
          listTpTube: "",
          tpCustomerId: "",
          features: []
        },
        feature: '',
        edit: false,
        editId: '',
        showAddModal: false,
        showDeleteItem: false
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.getPlanes()
      this.getMarca()
      this.getTipoCliente()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      addFeature () {
        this.plan.features.push(this.feature)
        this.feature = ''
      },
      
      borrarFeature (index) {
        this.plan.features.splice(index, 1)
      },

      async confirmDeleteItem(item) {
        await api.deletePlan(this.authUser, item.id)
        this.getPlanes()
      },

      async getPlanes()
      {
        const resp = await api.getPlanes(this.authUser)
        resp.forEach((it) => {
            it.acciones = 'eoo'
            it.showDetails = false
            it.nameCliente = it.tpCustomerDto.name
        })
        //console.log(resp)
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.itemsFiltro = resp
        }
        this.items.forEach((it) => {
            it.acciones = 'ee'
        })
      },

      async getTipoCliente () {
        const resp = await api.getTipoCliente(this.authUser)
        this.tipoClientes = resp
      },

      async getMarca()
      {
        const resp = await api.getMarca(this.authUser)
        this.marcas = resp  
      },

      addItem() {
        this.plan = {
          nameLicense: "",
          games: "",
          features: "",
          price: "",
          listTpTube: "",
          tpCustomerId: "",
          features: []
        }
        this.showAddModal = true
        this.edit = false
      },

      async saveEvent() 
      {
        if (this.edit == true) {
            api.editPlan(this.authUser, this.plan, this.editId)
        } else {
            api.setPlanes(this.authUser, this.plan)
        }
      },   

      editItem (item) {
        this.cliente = item
        this.showAddModal = true
        const listId = []
        item.features?.forEach((it) => {
          listId.push(it.id)
        })
        const listId2 = []
        item.features?.forEach((it) => {
          listId2.push(it.id)
        })
        this.plan = {
          nameLicense: item.name,
          games: item.games,
          price: item.price,
          listTpTube: item.listTpTube,
          listTpTubeListado: item.listId2,
          tpCustomerId: item.tpCustomerId.id,
          features: item.listId,
          featuresListado: item.features
        }
        this.edit = true
        this.editId = item.id
      },

    //   confirmDeleteItem (item) {
    //     this.cliente = item
    //     this.showDeleteItem = true
    //   },

      async deleteItem () {
        const response = await api.deleteCliente(this.authUser, this.cliente.id)
        if (response == undefined || response == null)
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            return
        }   
        this.showDeleteItem = false 
        SwalMixin('Cliente eliminado', 'success')
        await this.getPlanes()
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.name !== null && this.filterData.name !== '') {
            if (!it.name.includes(this.filterData.name)) {
              filtro = false
            }
          }

          if (this.filterData.surnames !== null && this.filterData.surnames !== '') {
            if (!it.surnames.includes(this.filterData.surnames)) {
              filtro = false
            }
          }

          if (this.filterData.email !== null && this.filterData.surnames !== '') {
            if (!it.surnames.includes(this.filterData.email)) {
              filtro = false
            }
          }

          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          name: null,
          surnames: null,
          email: null
        }
        this.getPlanes()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}
  
.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}

.btnAddCenter {
		border: 1px solid #003d52;
		border-radius: 5px;
		background: #003d52;
		color: #fff;
		font-weight: bold;
		border-radius: 5px;
		padding: 10px;
		max-width: 200px;
		height: 45px;
		font-size: 87.5%;
	}
</style>