<template>
	<div>
	  <stacked-bar-chart :chart-data="chartData" :chart-option="chartOption"></stacked-bar-chart>
	</div>
</template>

<script>
	import StackedBarChart from "./StackedBarChart.vue";

	export default {
		props: ["doorIndicates"],
		components: {
			StackedBarChart,
		},
		data() {
			return {
				chartData: {
					labels: ["",],
					datasets: null,
				},
				chartOption: {
					legend: {
						display: false,
					},
					title: {
						display: false,
					},
					scales: {
						yAxes: [{
							stacked: true,
							ticks: {
								font: {
									size: 8,
								},
							},
						}],
						xAxes: [ {
							stacked: true
						}]
					},
				},
			};
		},
		computed: {
			setData() {
				return [
					{
					label: "Activadas",
					backgroundColor: '#33FFDA',
					data: [this.doorIndicates.active],
					stack: "stack1",
					},
					{
					label: "Primer uso",
					backgroundColor: "#52FF33",
					data: [this.doorIndicates.firstUsed],
					stack: "stack1",
					},
					{
					label: "Segundo uso",
					backgroundColor: "#FFCE33",
					data: [this.doorIndicates.secondUsed],
					stack: "stack1",
					},
					{
					label: "Advertencias",
					backgroundColor: "#FF3933",
					data: [this.doorIndicates.warning],
					stack: "stack1",
					},	
				];
			}
		},
		watch: {
			doorIndicates: {
				handler(newVal) {
					this.chartData.datasets = this.setData;
				},
				immediate: true,
			},
		},
	};
</script>	