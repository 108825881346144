<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ico_user inlineBlock iconVending mr-0"></div>
              Clientes
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Listado de clientes</h3>
                  </b-col>   
                  <!-- b-col cols="6" class="text-right">
                    <base-button
                      @click.prevent="addItem"
                      type="primary"
                      native-type="submit"
                      >Nuevo</base-button
                    >
                  </b-col -->
                </b-row>   
                <b-row>
                <b-col cols="6">
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
              
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="6">
                      <base-input
                        v-model="filterData.name"
                        label="Nombre"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    <b-col md="6">
                      <base-input
                        v-model="filterData.surnames"
                        label="Apellidos"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                    <b-col md="6">
                      <base-input
                        v-model="filterData.email"
                        label="Correo"
                        prepend-icon="fas fa-user"
                      ></base-input>
                    </b-col>
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                </b-row>
                <b-row>  
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="btnFilter">Filtrar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="filter" type="primary" native-type="submit" class="bgTransparent">Cerrar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(acciones)="row">
                  <b-button variant="info" size="sm" @click="editItem(row.item)"><img src="../../../public/editar.png" class="mxW15x" title="Editar"></b-button>
                  <b-button variant="secondary" size="sm" @click="confirmDeleteItem(row.item)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- add customer -->
    <modal :show.sync="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          v-model="cliente.name"
          label="Nombre"
          prepend-icon="fas fa-user"
          placeholder="Nombre"
        ></base-input>
        <base-input
          v-model="cliente.surnames"
          label="Apellidos"
          prepend-icon="fas fa-user"
          placeholder="Apellidos"
        ></base-input>
        <base-input
          v-model="cliente.email"
          label="Email"
          prepend-icon="fas fa-user"
          placeholder="Email"
        ></base-input>
      </form>

      <template  v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveEvent"
        >
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Cerrar
        </button>
      </template>

    </modal>

    <modal :show="showDeleteItem" modal-classes="modal-secondary">
      <div class="flex align-items-center justify-content-center">
        <h3>Confirmar</h3>
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>¿Estas seguro que deseas eliminar el Cliente?</span>
      </div>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deleteItem"
        >
          Sí
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="showDeleteItem=false"
        >
          No
        </button>
      </template>
    </modal>

  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'name', label: 'Nombre' },
          { key: 'surnames', label: 'Apellidos' },
          { key: 'email', label: 'Correo' },
          { key: 'tpLicenseDto.name', label: 'Licencia' },
          { key: 'tpPaymentLicenseDto.name', label: 'Pago' },
          { key: 'tpSexDto.name', label: 'Sexo' },
          { key: 'date', label: 'Fecha' },
          //{ key: 'acciones', label: ' ' }
        ],
        items: [],
        busqueda: false,
        filterData: {
          name: null,
          surnames: null,
          email: null
        },

        cliente: {},
        showAddModal: false,
        showDeleteItem: false
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.getClientes()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      async getClientes()
      {
        const resp = await api.getClientes(this.authUser)
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.itemsFiltro = resp
        }  
      },

      addItem() {
        this.cliente = {
          name: "",
          surnames: "",
          email: "",
        }
        this.showAddModal = true
      },

      async saveEvent() 
      {
        if (
          this.cliente.name == "" ||
          this.cliente.surnames == "" ||
          this.cliente.email == ""
        ) 
        {
          SwalMixin('Valores inválidos. Favor corregir', 'error')
          return
        }
        let response = null
        if (this.cliente.id == null) {
          response = await api.setCliente(this.authUser, this.cliente)
        }
        if (this.cliente.id != null)
        {
          response = await api.updateCliente(this.authUser, this.cliente)
        }    
        //console.log(response)
        if (response == undefined || response == null || response.code == null || (response.code != '200' && response.code != '201' ))
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            this.getClientes()
            return
        }   
        SwalMixin(response.message, 'success')
        this.getClientes()
        this.showAddModal = false
      },   

      editItem (item) {
        this.cliente = item
        this.showAddModal = true
      },

      confirmDeleteItem (item) {
        this.cliente = item
        this.showDeleteItem = true
      },

      async deleteItem () {
        const response = await api.deleteCliente(this.authUser, this.cliente.id)
        if (response == undefined || response == null)
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            return
        }   
        this.showDeleteItem = false 
        SwalMixin('Cliente eliminado', 'success')
        await this.getClientes()
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.name !== null && this.filterData.name !== '') {
            if (!it.name.includes(this.filterData.name)) {
              filtro = false
            }
          }

          if (this.filterData.surnames !== null && this.filterData.surnames !== '') {
            if (!it.surnames.includes(this.filterData.surnames)) {
              filtro = false
            }
          }

          if (this.filterData.email !== null && this.filterData.email !== '') {
            if (!it.email.includes(this.filterData.email)) {
              filtro = false
            }
          }

          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          name: null,
          surnames: null,
          email: null
        }
        this.getClientes()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}
  
.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}
</style>