<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ico_tennis inlineBlock iconVending mr-0"></div>
              Partidos
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Listado de partidos</h3>
                  </b-col>   
                  <!-- b-col cols="6" class="text-right">
                    <base-button
                      @click.prevent="addItem"
                      type="primary"
                      native-type="submit"
                      >Nuevo</base-button
                    >
                  </b-col -->
                </b-row>   
                <b-row>
                <b-col cols="12" class="text-right">
                 
                </b-col>
              </b-row>
              <card>
                <b-row>
                  <b-col md="4">
                      <base-input label="Centro Deportivo">
                        <el-select 
                          v-model="filterData.sportCenterId"
                          filterable
                          placeholder="Seleccione..."
                        >
                          <el-option 
                          v-for="vending in vendings"
                          :key="vending.id"
                          :label="vending.sportCenterDto.sportCenterName"
                          :value="vending.sportCenterDto.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Fecha">
                    <flat-picker
                      class="form-control datepicker"
                      :config="flatPickerConfig"
                      v-model="filterData.date1"
                      style="background: #fff"
                    >
                    </flat-picker>
                    </base-input>
                  </b-col>
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                </b-row>
                <b-row>  
                  <b-col md="6" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="btnFilter">Buscar</base-button>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(date)="row">
                  {{ formatearFecha(row.item.date) }}
                </template>  
                <template #cell(tpTubeDto)="row">
                  <img
                    :src="item.tpTubeDto.urlImage"
                    width="20"
                    class="mr-2"
                  />{{ row.item.tpTubeDto.name }} 
                </template>
                <template #cell(customerDto)="row">
                  {{ row.item.customerDto.name }} {{ row.item.customerDto.surnames }}
                </template>  
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import 'flatpickr/dist/l10n/es';

  export default {
    name: 'form-components',
    components: {
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'date', label: 'Fecha' },
          { key: 'time', label: 'Hora' },
          { key: 'customerDto', label: 'Cliente' },
          { key: 'tpTubeDto', label: 'Marca' },
          { key: 'sportCenterDto.sportCenterName', label: 'Centro deportivo' },
          { key: 'scoreId', label: 'Puntuacion' },
          { key: 'observation', label: 'Observación' },
          //{ key: 'acciones', label: ' ' }
        ],
        items: [],
        vendings: [],
        itemsFiltro: [],
        busqueda: false,
        filterData: {
          sportCenterId: null,
          date1: null
        },
        
        partido: {},
        showAddModal: false,
        showDeleteItem: false,
        flatPickerConfig: {
          dateFormat: 'd/m/Y',     
          locale: 'es',    
        },
        info: 'Seleccione una fecha específica en búsqueda avanzada...'
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      await this.getPartidos();
      await this.getVendings();
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      async getVendings()
      {
        const resp = await api.getVendings(this.authUser);

        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.vendings = resp
        }  
      },

      async getPartidos()
      {
        let filterDate1 = null;
        if (this.filterData.date1 !== null && this.filterData.date1 !== '') {
          filterDate1 = this.formatearAFechaIngles(this.filterData.date1);
        }  
        const resp = await api.getPartidos(this.authUser, filterDate1)
        this.info = 'Seleccione una fecha específica en búsqueda avanzada...'
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp;
          this.itemsFiltro = resp;
          if (filterDate1 !== null) {
            this.info = filterDate1;
          }
        }  
      },

      async bsqFilter () {
        if (this.filterData.date1 !== null && this.filterData.date1 !== '') {
          await this.getPartidos();
        }
        
        this.items = [];
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.sportCenterId !== null) {
            if (it.sportCenterDto.id !== this.filterData.sportCenterId) {
              filtro = false
            }
          }

          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          sportCenterId: null,
          date1: null
        }
        this.info = 'Seleccione una fecha específica en búsqueda avanzada...';
        this.getPartidos()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      formatearFecha(fecha) {
        // Crea una instancia de la clase Date con la fecha proporcionada
        if (this.isDateValid(fecha) === false) {
          return fecha;
        }

        const date = new Date(fecha);
        
        // Obtiene los componentes de la fecha
        const dia = date.getUTCDate();
        const mes = date.getUTCMonth() + 1; // Los meses en JavaScript son base 0, por lo que se suma 1
        const anio = date.getUTCFullYear();

        // Formatea la fecha en el formato deseado (por ejemplo, DD/MM/YYYY)
        const fechaFormateada = `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${anio}`;

        return fechaFormateada;
      },

      formatearAFechaIngles(fecha) {
        fecha = fecha.split("/");
        fecha = fecha[2] + '-' + fecha[1] + "-" + fecha [0];
       // console.log(fecha)
        return fecha;
      },
      
      isDateValid(dateString) {
        const date = new Date(dateString);
        return !isNaN(date);
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}

.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}
</style>