<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ico_user2 inlineBlock iconVending mr-0"></div>
              Gestión de usuarios
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Listado de usuarios</h3>
                  </b-col>   
                </b-row>   
                <b-row>
                <b-col cols="6">
                  <base-button @click.prevent="filter()" type="primary" native-type="submit" class="btnBusqueda w200X"><img src="../../../public/setting.svg" class="mxW13x" alt=""> BÚSQUEDA AVANZADA</base-button>
                </b-col>
                <b-col cols="6" class="text-right">
                  <div>                   
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="W20x"><img src="../../../public/export-file.svg" class="mxW25x" alt=""></div>
                      </template>
                      <b-dropdown-item>Exportar PDF</b-dropdown-item>
                      <b-dropdown-item>Exportar CSV</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <card v-if="busqueda">
                <b-row>
                  <b-col md="3">
                      <base-input
                        v-model="filterData.name"
                        label="Nombre"
                        prepend-icon="fas fa-user"
                      ></base-input>
                  </b-col>
                  <b-col md="3">
                      <base-input
                        v-model="filterData.lastname"
                        label="Apellido"
                        prepend-icon="fas fa-user"
                      ></base-input>
                  </b-col>
                  <b-col md="3">  
                    <base-input label="Estado">
                    <el-select 
                          v-model="filterData.enabled"
                          filterable
                          placeholder="Seleccione..."
                        >
                          <el-option 
                          v-for="state in states"
                          :key="state.id"
                          :label="state.name"
                          :value="state.id"
                          >
                          </el-option>
                        </el-select>
                    </base-input>
                  </b-col>
                  <b-col md="3">  
                    <base-input label="Perfil">
                    <el-select 
                          v-model="filterData.tpProfileId"
                          filterable
                          placeholder="Seleccione..."
                        >
                          <el-option 
                          v-for="perfil in profiles"
                          :key="perfil.id"
                          :label="perfil.name"
                          :value="perfil.id"
                          >
                          </el-option>
                        </el-select>
                    </base-input>
                  </b-col>
                  <!-- <b-col md="3" style="text-align:center;" /> -->
                </b-row>
                <b-row>  
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="bsqFilter" type="primary" native-type="submit" class="">Filtrar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <base-button @click.prevent="filter" type="primary" native-type="submit" class="bgTransparent">Cerrar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(enabled)="row">
                  {{ getEstadoName(row.item.enabled) }}
                </template> 
                <template #cell(tpProfileId)="row">
                  {{ getPerfilName(row.item.tpProfileId) }}
                </template> 
                <template #cell(acciones)="row">
                  <b-button variant="info" size="sm" @click="editItem(row.item)"><img src="../../../public/editar.png" class="mxW15x" title="Editar"></b-button>
                  <b-button variant="secondary" size="sm" @click="confirmDeleteItem(row.item)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'
  import flatPicker from "vue-flatpickr-component";
  /* import "flatpickr/dist/flatpickr.css"; */

  export default {
    name: 'form-components',
    components: {
      /* flatPicker, */
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
          { key: 'name', label: 'Nombre' },
          { key: 'lastname', label: 'Apellido' },
          { key: 'email', label: 'Email' },
          { key: 'enabled', label: 'Estado' },
          { key: 'tpProfileId', label: 'Perfil' },

          //{ key: 'acciones', label: ' ' }
        ],
        states: [
        { id: 1, name: 'Activo' },
        { id: 2, name: 'Inactivo' },
        ],
        profiles: [
        { id: 1, name: 'Administrador' },
        { id: 2, name: 'Mantenimiento' },
        ],
        items: [],
        busqueda: false,
        filterData: {
          name: null,
          lastname: null,
          enabled: null,
          tpProfileId: null
        },
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      //SwalMixin('Gestión de usuarios en contrucción. Pruebe más tarde.', 'warning')
			//this.$router.push('listadoTickets');
      this.getUsuarios()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      async getUsuarios()
      {
        const resp = await api.getUsuarios(this.authUser)
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.itemsFiltro = resp
        }  
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.name !== null && this.filterData.name !== '') {
            if (!it.name.includes(this.filterData.name)) {
              filtro = false
            }
          }
          if (this.filterData.lastname !== null && this.filterData.lastname !== '') {
            if (!it.lastname.includes(this.filterData.lastname)) {
              filtro = false
            }
          }
          if (this.filterData.enabled !== null) {
            if (it.enabled !== this.filterData.enabled) {
              filtro = false
            }
          }
          if (this.filterData.tpProfileId !== null) {
            if (it.tpProfileId !== this.filterData.tpProfileId) {
              filtro = false
            }
          }
          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          name: null,
          lastname: null,
          enabled: null,
          tpProfileId: null
        }
        this.getUsuarios()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      getEstadoName(enabledId) {
        if (enabledId === 1) {
          return "Activo"
        }
        return "Inactivo"
      },

      getPerfilName(profileId) {
        if (profileId === 1) {
          return "Administrador"
        }
        return "Mantenimiento"
      }  
    },
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}

.mxW15x{
  max-width: 15px;
}
</style>