<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <div class="ico-sidebar ni ni-settings inlineBlock iconVending mr-0"></div>
              Mantenimiento
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Listado de mantenimiento</h3>
                  </b-col>   
                </b-row>   
                <b-row>
                <b-col cols="12" class="text-right">
                  <div>                   
                    <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="W20x"><img src="../../../public/export-file.svg" class="mxW25x" alt=""></div>
                      </template>
                      <b-dropdown-item>Exportar PDF</b-dropdown-item>
                      <b-dropdown-item>Exportar CSV</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <card>
                <b-row>
                  <b-col md="4">
                      <base-input label="Centro Deportivo">
                        <el-select 
                          v-model="filterData.vendingId"
                          filterable
                          placeholder="Seleccione..."
                        >
                          <el-option 
                          v-for="vending in vendings"
                          :key="vending.id"
                          :label="vending.sportCenterDto.sportCenterName	+ ' || ' +  vending.serialNumber"
                          :value="vending.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Rango: Fecha 1">
                    <flat-picker
                      class="form-control datepicker"
                      :config="flatPickerConfig"
                      v-model="filterData.date1"
                      style="background: #fff"
                    >
                    </flat-picker>
                    </base-input>
                  </b-col>
                  <b-col md="4">
                    <base-input label="Fecha 2">
                    <flat-picker
                      class="form-control datepicker"
                      :config="flatPickerConfig"
                      v-model="filterData.date2"
                      style="background: #fff"
                    >
                    </flat-picker>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>  
                  <b-col md="6" class="mb-3">
                    <base-button @click.prevent="showMantenimiento" type="primary" native-type="submit" class="btnFilter">Buscar</base-button>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <base-button @click.prevent="resetFilters" type="primary" native-type="submit" class="bgTransparent">Limpiar</base-button>
                  </b-col>
                </b-row>
              </card>
              <b-table :items="items" :fields="fields" striped responsive="sm">
                <template #cell(date)="row">
                  {{ formatearFecha(row.item.date) }}
                </template>  
                <template #cell(tpStatusDto)="row">
                  <span :style="{backgroundColor: '#' + row.item.tpStatusDto.color}" class="circle"></span>
									<span>{{ row.item.tpStatusDto.name }}</span>
                </template>  
                <template #cell(tpTubeDto)="row">
                  <img
                    :src="`/logos/${row.item.tpTubeDto.id}.svg`"
                    width="20"
                    class="mr-2"
                  />{{ row.item.tpTubeDto.name }} 
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import 'flatpickr/dist/l10n/es';

  export default {
    name: 'form-components',
    components: {
      flatPicker,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        fields: [
          { key: 'date', label: 'Fecha' },
          { key: 'time', label: 'Hora' },
          { key: 'doorNumber', label: 'Puertas' },
          { key: 'tpStatusDto', label: 'Estado' },
          { key: 'tpTubeDto', label: 'Marca' },
        ],
        items: [],
        vendings: [],
        itemsFiltro: [],
        busqueda: false,
        filterData: {
          vendingId: null,
          date1: null,
          date2: null, 
        },
        flatPickerConfig: {
          dateFormat: 'd/m/Y',  
          locale: 'es',        
        },
        sportCenterName: 'Seleccione un centro deportivo en búsqueda avanzada...'
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.getVendings()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),

      async getVendings()
      {
        const resp = await api.getVendings(this.authUser);

        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.vendings = resp
        }  
      },

      async showMantenimiento()
      {
        let filterDate1 = null;
        let filterDate2 = null;

        if (this.filterData.vendingId == null) {
          SwalMixin('Debe seleccionar un Centro Deportivo. Favor verificar', 'error')
					return
        }
        if (this.filterData.date1 !== null && this.filterData.date2 === null)  {
          this.filterData.date2 = this.filterData.date1;
        }
        if (this.filterData.date1 === null)  {
          this.filterData.date2 = null;
        }
        if (this.filterData.date1 !== null && this.filterData.date1 !== ''
          ) {
          filterDate1 = this.formatearAFechaIngles(this.filterData.date1);
        }   
        if (this.filterData.date2 !== null && this.filterData.date2 !== ''
          ) {
          filterDate2 = this.formatearAFechaIngles(this.filterData.date2);
        }   
        if (filterDate1 !== null && filterDate2 !== null) { 
          if (new Date(filterDate1).getTime() > new Date(filterDate2).getTime()) {
            SwalMixin('El rango de fechas está invertido. Favor verificar', 'error')
					  return
          }
        }      
        const resp = await api.showMantenimiento(this.authUser, this.filterData.vendingId, filterDate1, filterDate2);
        this.items = [];
        this.sportCenterName = 'Seleccione un centro deportivo en búsqueda avanzada...';
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.sportCenterName = this.getSportCenterNameById(this.filterData.vendingId);
        }  
      },

      getSportCenterNameById(id) {
        const vending = this.vendings.find(v => v.id === id);
        return vending ? vending.sportCenterDto.sportCenterName	+ ' || ' +  vending.serialNumber : '';
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.name !== null && this.filterData.name !== '') {
            if (!it.sportCenterDto.sportCenterName.includes(this.filterData.name)) {
              filtro = false
            }
          }

          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      async resetFilters() {
        this.filterData = {
          vendingId: null,
          date1: null,
          date2: null  
        }
        this.items = [];
        this.sportCenterName = 'Seleccione un centro deportivo en búsqueda avanzada...';
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      formatearFecha(fecha) {
        // Crea una instancia de la clase Date con la fecha proporcionada
        const date = new Date(fecha);

        // Obtiene los componentes de la fecha
        const dia = date.getUTCDate();
        const mes = date.getUTCMonth() + 1; // Los meses en JavaScript son base 0, por lo que se suma 1
        const anio = date.getUTCFullYear();

        // Formatea la fecha en el formato deseado (por ejemplo, DD/MM/YYYY)
        const fechaFormateada = `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${anio}`;

        return fechaFormateada;
      },

       formatearAFechaIngles(fecha) {
        fecha = fecha.split("/");
        fecha = fecha[2] + '-' + fecha[1] + "-" + fecha [0];
       // console.log(fecha)
        return fecha;
      }  
    },
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 13px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}

.mxW15x{
  max-width: 15px;
}

.circle {
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		margin-right: 5px;
}
	
.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}
</style>