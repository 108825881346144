<template>
	<div class="dashboard-doti__main">
		<base-header class="pb-6">
			<!-- <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6" class="breadcrumb__wrapper">
          <h6 class="h2 d-inline-block mb-0">DASHBOARD</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col cols="12">
          <base-input label="Filtro tiendas">
            <el-select v-model="selectsTienda" filterable @change="changeFilter()">
              <el-option label="Tienda 1" value="tienda1">Tienda 1</el-option>
              <el-option label="Tienda 2" value="tienda2">Tienda 2</el-option>
              <el-option label="Tienda 3" value="tienda3">Tienda 3</el-option>
            </el-select>
          </base-input>
        </b-col>
      </b-row> -->
			<!-- Card stats -->
			<!--NUEVOS CARDS-->
			<div class="carddoti_container">
				<div class="row mb-5">
					<div class="col-lg-6 col-md-6 col-sm-12 my-3">
						<div class="carddoti carddoti_client">
							<div class="carddoti_wrapper">
								<div class="carddoti__header carddoti__header-newclient">
									<h3>Nuevos clientes</h3>
								</div>
								<div class="carddoti__footer">
									<p class="state state_positive">{{ nuevoClientesP }}%</p>
									<p class="datesince">Desde el mes pasado</p>
								</div>
							</div>
							<div class="card-doti__calc">{{ nuevoClientes }}</div>
						</div>
					</div>
					<!-- <div class="col-lg-6 col-md-6 col-sm-12 my-3">
            <div class="carddoti carddoti_trafic">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-trafic">
                    <h3>Cliente dormido</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_positive">{{ traficoTotalP }}%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ traficoTotal }}</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 my-3">
            <div class="carddoti carddoti_ventas">
                <div class="carddoti_wrapper">
                  <div class="carddoti__header carddoti__header-ventas">
                    <h3>Ventas</h3>
                  </div>
                  <div class="carddoti__footer">
                    <p class="state state_negative">{{ ventasP }}%</p>
                    <p class="datesince">Desde la semana pasada</p>
                  </div>
                </div>
                <div class="card-doti__calc">{{ ventas }}€</div>
            </div>
          </div> -->
					<div class="col-lg-6 col-md-6 col-sm-12 my-3">
						<div class="carddoti carddoti_devoluciones">
							<div class="carddoti_wrapper">
								<div class="carddoti__header carddoti__header-devoluciones">
									<h3>Licencias</h3>
								</div>
								<div class="carddoti__footer">
									<p class="state state_positive">{{ devolucionesP }}%</p>
									<p class="datesince">Desde el mes pasado</p>
								</div>
							</div>
							<div class="card-doti__calc">{{ devoluciones }}</div>
						</div>
					</div>
				</div>
			</div>
		</base-header>

		<!--Charts-->
		<b-container fluid class="mt--6">
			<b-row>
				<b-col xl="12">
					<card header-classes="bg-transparent">
						<chart-double />
					</card>
				</b-col>
				<!-- <b-col xl="6">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h4 class="text-uppercase ls-1 mb-1">En establecimiento</h4>
                <div class="carddoti__header carddoti__header-ventas">
                <h3> Ticket medio de cliente</h3>
                </div>
              </b-col>
            </b-row>

            <bar-chart
              :height="350"
              
              ref="barChart"
              :chart-data="redBarChart2.chartData"
            >
            </bar-chart>
          </card>
        </b-col>

        <b-col xl="6">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h4 class="text-uppercase ls-1 mb-1">Fuera del establecimiento</h4>
                <div class="carddoti__header carddoti__header-ventas">
                <h3> Ticket medio de cliente</h3>
                </div>
              </b-col>
            </b-row>

            <bar-chart
              :height="350"
              
              ref="barChart"
              :chart-data="redBarChart3.chartData"
            >
            </bar-chart>
          </card>
        </b-col> -->

				<b-col xl="6">
					<card header-classes="bg-transparent">
						<pie-chart />
					</card>
				</b-col>

				<!-- <b-col xl="6">
          <card header-classes="bg-transparent">
            <b-row align-v="center" slot="header">
              <b-col>
                <h4 class="text-uppercase ls-1 mb-1">Ventas por segmentación</h4>
                <div class="carddoti__header carddoti__header-ventas">
                <h3>Total Ventas</h3>
                </div>
              </b-col>
            </b-row>

            <bar-chart
              :height="350"
              
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </b-col> -->
			
				<b-col xl="6">
					<div class="card">
						<grafico-quesito
							class="mt20x"
							:dataEnvio="dataEnvio"
							:ventasTitulo="ventasTitulo"
						></grafico-quesito>
					</div>
				</b-col>
			
			</b-row>
			<!-- End charts-->

			<!-- <b-row>
        <b-col xl="6">
          <social-traffic-table></social-traffic-table>
        </b-col>
        <b-col xl="6">
          <div class="card">
            <grafico-quesito class="mt20x" :dataEnvio="dataEnvio" :ventasTitulo="ventasTitulo"></grafico-quesito>
          </div>
        </b-col>
      </b-row> -->
		</b-container>
	</div>
</template>

<script
	type="text/javascript"
	src="https://cdn.jsdelivr.net/npm/chart.js@3.0.0/dist/chart.min.js"
></script>

<script>
	// Charts
	import * as chartConfigs from '@/components/Charts/config';
	import LineChart from '@/components/Charts/LineChart';
	import BarChart from '@/components/Charts/BarChart';
	import PieChart from './PieChart';
	import ChartDouble from './ChartDouble';

	// Components
	import BaseProgress from '@/components/BaseProgress';
	import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
	import StatsCard from '@/components/Cards/StatsCard';

	// Lists
	import ActivityFeed from './ActivityFeed';
	import TaskList from './TaskList';
	import UserList from './UserList';
	import ProgressTrackList from './ProgressTrackList';

	// Tables
	import LightTable from './LightTable';
	import SocialTrafficTable from './SocialTrafficTable';
	// import PageVisitsTable from './PageVisitsTable';
	import GraficoQuesito from './GraficoQuesito';

	import { Select, Option } from 'element-ui';
	import { Charts } from '@/components/Charts/config';
	export default {
		components: {
			// ActivityFeed,
			// LineChart,
			BarChart,
			PieChart,
			ChartDouble,
			// BaseProgress,
			// RouteBreadCrumb,
			// StatsCard,
			// TaskList,
			// PageVisitsTable,
			GraficoQuesito,
			SocialTrafficTable,
			// LightTable,
			// UserList,
			// ProgressTrackList,
			[Select.name]: Select,
			[Option.name]: Option,
		},
		data() {
			return {
				selectsTienda: 'tienda1',
				nuevoClientesP: '3,48',
				traficoTotalP: '2,48',
				ventasP: '5,72',
				devolucionesP: '3.48',
				devoluciones: '19',
				ventas: '386,24',
				traficoTotal: '150',
				nuevoClientes: '8',
				dataEnvio: [
					// ["27504", 34.7],
					['Personal', 19.8],
					['Grupo', 85.8],
					['Empresas', 46.8],
				],
				ventasTitulo: 'Estadisticas de licencias',
				bigLineChart: {
					allData: [
						[0, 20, 10, 30, 15, 40, 20, 60, 60],
						[0, 20, 5, 25, 10, 30, 15, 40, 40],
					],
					activeIndex: 0,
					chartData: {
						datasets: [
							{
								label: 'Performance',
								data: [0, 50, 100, 150, 200, 250, 300, 350, 400],
							},
						],
						labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				redBarChart: {
					chartData: {
						labels: [
							'G.Silenciosa',
							'Baby boomers',
							'Generación X',
							'Generación Z',
							'Millenials',
						],
						datasets: [
							{
								label: 'Sales',
								data: [0, 50, 100, 150, 200, 250, 300, 350, 400],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				redBarChart2: {
					chartData: {
						labels: [
							'G.Silenciosa',
							'Baby boomers',
							'Generación X',
							'Generación Z',
							'Millenials',
						],
						datasets: [
							{
								label: 'Sales',
								data: [200, 75, 120, 50, 225, 25, 300, 150, 140],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				redBarChart3: {
					chartData: {
						labels: [
							'G.Silenciosa',
							'Baby boomers',
							'Generación X',
							'Generación Z',
							'Millenials',
						],
						datasets: [
							{
								label: 'Sales',
								data: [225, 25, 300, 150, 140],
							},
						],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
			};
		},
		methods: {
			initBigChart(index) {
				let chartData = {
					datasets: [
						{
							label: 'Performance',
							data: this.bigLineChart.allData[index],
						},
					],
					labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				};
				this.bigLineChart.chartData = chartData;
				this.bigLineChart.activeIndex = index;
			},

			//   changeFilter () {
			//     if (this.selectsTienda === 'tienda1') {
			//       this.nuevoClientesP = '3,48'
			//       this.traficoTotalP = '2,48'
			//       this.ventasP = '5,72'
			//       this.devolucionesP = '3.48'
			//       this.devoluciones = '49,65'
			//       this.ventas = '924'
			//       this.traficoTotal = '2,356'
			//       this.nuevoClientes = '350,897'
			//     } else if (this.selectsTienda === 'tienda2') {
			//       this.nuevoClientesP = '2,48'
			//       this.traficoTotalP = '1,48'
			//       this.ventasP = '4,72'
			//       this.devolucionesP = '2.48'
			//       this.devoluciones = '55,65'
			//       this.ventas = '224'
			//       this.traficoTotal = '1,150'
			//       this.nuevoClientes = '100,897'
			//     } else if (this.selectsTienda === 'tienda3') {
			//       this.nuevoClientesP = '4,48'
			//       this.traficoTotalP = '3,48'
			//       this.ventasP = '1,72'
			//       this.devolucionesP = '2,48'
			//       this.devoluciones = '25,65'
			//       this.ventas = '200'
			//       this.traficoTotal = '4,250'
			//       this.nuevoClientes = '141,897'
			//     }
			//   }
		},
		mounted() {
			//SwalMixin('Dashboard en contrucción. Pruebe más tarde.', 'warning')
			//this.$router.push('listadoTickets');
		},

		//mounted() {
			//   this.initBigChart(0);
			//   new Chart(document.querySelector('canvas').getContext('2d'), {
			//     type: 'bar',
			//     data: {
			//         labels: ['1','2','3','4','5','6'],
			//         datasets: [{
			//             label: 'Sales',
			//             data: [20,30,-10,20,30, -30],
			//             fill: true,
			//             borderRadius: 20,
			//             borderColor: '#5eb8ff',
			//             backgroundColor: '#5eb8ff',
			//             datalabels: {
			//                 display: false
			//             }
			//         }]
			//     }
			// });
		//},
	};
</script>
<style>
	.highcharts-title {
		font-size: 16px !important;
		font-weight: bold !important;
		color: #3e6b88 !important;
		fill: #3e6b88 !important;
		display: flex !important;
	}
	.mt20x {
		margin-top: 20px;
	}
</style>
