<template>
	<div>
		<base-header class="pb-6"> </base-header>
		<b-container fluid class="mt--6">
			<b-row class="mx-auto">
				<b-col class="col-vending">
					<div class="card-wrapper">
						<!-- Input groups -->
						<card style="padding-bottom: 5px">
							<div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
							Dashboard /
							<div class="ico-sidebar ico_comercio inlineBlock iconVending mr-0"></div>
							Vendings
						</card>
						<card>
							<b-row class="borderGrey mb-3">
								<b-col cols="6">
									<h3 class="mb20x">LISTADO DE VENDINGS</h3>
								</b-col>
								<b-col cols="6" class="text-right">
									<base-button
									@click.prevent="addItem"
									type="primary"
									native-type="submit"
									:class="'btnAddCenter'"
									>Nuevo</base-button
									>
								</b-col>
							</b-row>	
							<b-row class="borderGrey mb-3">	
								<b-col cols="12" class="text-right">
									<div class="inlineBlock relative">
										<!-- <input
											v-model="user"
											class="inputLogin"
											id="user"
											type="text"
											name="username"
											placeholder="Buscar..."
											required
										/> -->
										<input
											class="inputLogin"
											v-model="searchFilter"
											id="user"
											type="text"
											name="username"
											placeholder="Buscar..."
											required
										/>
										<img
											src="../../../public/search.svg"
											class="mxW25x iconBuscador"
											alt=""
										/>
									</div>
									<b-dropdown
										size="lg"
										class="bg-grey"
										variant="link"
										toggle-class="text-decoration-none"
										no-caret
									>
								
										
									</b-dropdown>
								</b-col>
							</b-row>

							<b-row>
								<b-col cols="12" v-for="(item, index) in items" :key="index">
									<div
										@click="viewSportCenter(item.id, index)"
										:class="
											mostrar1[index]===true
												? 'bgPrimary blanco p15x bold radius'
												: 'borderAzul p15x bold'
										"
									>
										{{ item.sportCenterName }}
										<div class="fRight">
											<img
												src="/chevron-down-solid.svg"
												alt=""
												class="mt-0"
												width="15"
												height="15"
											/>
										</div>
									</div>
									<div v-if="mostrar1[index]===true" class="borderPrimary bgGrey">
										<b-row v-for="(vending, index) in vendingList"  :key="index">
											<b-col lg="4" style="padding: 0px 40px">
												<div class="card-wrapper">
													<card class="rounded-lg">
														<strong>% del estado de puertas</strong>
														<!-- pie-chart-2 / --><!-- Cambiar aca  -->
														<stacked-bar-chart-config :door-indicates="vending.indicateTubeDto"/>
													</card>
												</div>
											</b-col>
											<b-col lg="8" style="padding: 0px 40px; padding-left: 0px">
												<div class="card-wrapper">
													<card class="estado__puertas">
														<p>
															<strong class="mb-5">Estado de puertas por vending</strong>
														</p>
														<div class="estado__puertas-card">
															<div class="estado__puertas--title">
																Vending centro deportivo (interior)
															</div>
															<div class="estado__puertas--stats">
																<p class="stats1">
																	<span :style="{backgroundColor: '#33FFDA'}" class="circle"></span>
																	{{ vending.indicateTubeDto.active }}% <span>Activadas</span>
																</p>
																<p class="stats2">
																	<span :style="{backgroundColor: '#52FF33'}" class="circle"></span>
																	{{ vending.indicateTubeDto.firstUsed }}% <span>Primer uso</span>
																</p>
																<p class="stats3">
																	<span :style="{backgroundColor: '#FFCE33'}" class="circle"></span>
																	{{ vending.indicateTubeDto.secondUsed }}% <span>Segundo uso</span>
																</p>
																<p class="stats4">
																	<span :style="{backgroundColor: '#FF3933'}" class="circle"></span>
																	{{ vending.indicateTubeDto.warning }}% <span>Adventencia</span>
																</p>
															</div>
															<div class="d-flex justify-content-end mb-4 mr-4">
																<select
																	name="estado-puertas"
																	@change="filtrarPuertas()"
																	v-model="selectedEstado"
																>
																	<option value="todas">Seleccionar estado</option>
																	<option v-for="(status, index) in statusList" :key="index" :value="status.name">{{ status.name }}</option>
																</select>
															</div>
															<b-row>
																<b-col lg="6" class="barra-invert px-6 puertas1">
																	<PuertaVending
																		v-for="(item, index) in firstGroup[vending]"
																		:key="index"
																		:id="item.id"
																		:number="item.doorNumber"
																		:backgroundColor="item.tpStatusDto.color"
																		:logo="item.tubeDto.urlImage"
																		:state="item.tubeDto.name"
																	/>
																</b-col>
																<b-col lg="6" class="barra-invert px-6 puertas1">
																	<PuertaVending
																		v-for="(item, index) in secondGroup[vending]"
																		:key="index"
																		:id="item.id"
																		:number="item.doorNumber"
																		:backgroundColor="'#' + item.tpStatusDto.color"
																		:logo="item.tubeDto.urlImage"
																		:state="item.tubeDto.name"
																	/>
																</b-col>
															</b-row>

															<b-row>
																<b-col class="last-cards">
																	<card class="estado__puertas--stats2">
																		<h5>Estados</h5>
																		<div>
																			<p v-for="(status, index) in statusList" :key="index" class="stats1 mr-3">
																				<span :style="{backgroundColor: status.color}" class="circle"></span>
																				<span>{{ status.name }}</span>
																			</p>
																		</div>
																	</card>
																	<card class="estado__puertas--stats3">
																		<h5>Marcas</h5>
																		<div>
																			<p v-for="(marca, index) in tubeList" :key="index" class="stats1 mr-1">
																				<img
																					:src="marca.urlImage"
																					width="13"
																					class="mr-2"
																				/>
																				<span>{{ marca.name }}</span>
																			</p>
																		</div>
																	</card>
																</b-col>
															</b-row>
														</div>
													</card>
												</div>
											</b-col>
										</b-row>
									</div>
								</b-col>
	
							</b-row>
						</card>
					</div>
				</b-col>
			</b-row>
		</b-container>
		 <!-- add centro deportivo -->
		<modal :show.sync="showAddModal" :size="'lg'" modal-classes="modal-secondary">
			<h3>Centro Deportivo</h3>
			<form class="new-event--form" @submit.prevent="saveEvent">
				<base-input
				v-model="sportCenterRequest.sportCenterName"
				label="Nombre"
				prepend-icon="fas fa-user"
				placeholder="Nombre"
				></base-input>
				<base-input
				v-model="sportCenterRequest.address"
				label="Dirección"
				prepend-icon="fas fa-user"
				placeholder="Dirección"
				></base-input>
				<base-input
				v-model="sportCenterRequest.city"
				label="Ciudad"
				prepend-icon="fas fa-user"
				placeholder="Ciudad"
				></base-input>
				<b-row>
					<b-col lg="6">
						<base-input
						v-model="sportCenterRequest.postalCode"
						label="Código Postal"
						prepend-icon="fas fa-user"
						placeholder="Código Postal"
						></base-input>
					</b-col>
					<b-col lg="6">
						<base-input
						v-model="sportCenterRequest.telephone"
						label="Teléfono"
						prepend-icon="fas fa-user"
						placeholder="Teléfono"
						></base-input>
					</b-col>
				</b-row>				
				<base-input
				v-model="serialNumber"
				label="# de Serial del Vending"
				prepend-icon="fas fa-user"
				placeholder="# de Serial del Vending"
				></base-input>
			</form>
			<card v-if="this.doorRequestList.length < 40">
				<b>Datos iniciales para crear grupos de 20 Puertas. Máximo 2 grupos.</b>
				<form class="new-event--form" @submit.prevent="saveDoor">
					<b-row>
						<b-col lg="4">
							<base-input label="Estado">
								<el-select 
									v-model="doorRequest.tpStatusId"
									filterable
									placeholder=""
								>
									<el-option 
									v-for="status in statusList"
									:key="status.id"
									:label="status.name"
									:value="status.id"
									>
									</el-option>
								</el-select>
							</base-input>
						</b-col>
						<b-col lg="4">	
							<base-input label="Marca">
								<el-select 
									v-model="doorRequest.tpTubeId"
									filterable
									placeholder=""
								>
									<el-option 
									v-for="tube in tubeList"
									:key="tube.id"
									:label="tube.name"
									:value="tube.id"
									>
									</el-option>
								</el-select>
							</base-input>
						</b-col>
						<b-col lg="4" class="text-center">
							<base-input label="Acción">
							<b-button class="btnAddCenter" variant="primary" size="sm" @click="addPuerta(doorRequest)">Agregar puertas</b-button>
							</base-input>
						</b-col>
					</b-row>			
				</form>
			</card>		
			<card>
				<b>Listado de Puertas</b>
				<div class="table-responsive">
				<b-table :items="doorRequestList" :fields="doorFields" striped responsive="sm">
					<template #cell(tpStatusId)="row">
						<span v-if="!row.item.editable">{{ getNombreEstado(row.item.tpStatusId) }}</span>
						<base-input v-if="row.item.editable">
						<el-select 
							v-model="row.item.tpStatusId"
							filterable
							placeholder=""
						>
							<el-option 
							v-for="status in statusList"
							:key="status.id"
							:label="status.name"
							:value="status.id"
							>
							</el-option>
						</el-select>
						</base-input>
					</template>	
					<template #cell(tpTubeId)="row">
						<span v-if="!row.item.editable">{{ getNombreMarca(row.item.tpTubeId) }}</span>
						<base-input v-if="row.item.editable">
							<el-select 
							v-model="row.item.tpTubeId"
							filterable
							placeholder=""
							>
							<el-option 
							v-for="tube in tubeList"
							:key="tube.id"
							:label="tube.name"
							:value="tube.id"
							>
							{{ tube.name }}
							</el-option>
							</el-select>
						</base-input>  
					</template>	
					<template #cell(acciones)="row">
						<div class="text-right">
							<b-button size="sm" @click="editRow(row)" v-if="!row.item.editable" variant="secondary"><img
							:src="`ico-pencil-64.svg`"
							width="13"
							height="13"
							title="Editar"
							/></b-button>
							
							<b-button size="sm" @click="saveRow(row)"  v-if="row.item.editable" variant="success" title="Guardar"><i class="ni ni-check-bold"></i></b-button>
							<b-button size="sm" @click="cancelEditRow(row)" v-if="row.item.editable" variant="danger" title="Cancelar"><b>X</b></b-button>	
						</div>
					</template>
				</b-table>
				</div>
			</card>	
			<template  v-slot:footer>
					<b-button
					size="sm"
					class="btnAddCenter"
					variant="primary"
					@click="saveEvent"
					>
					Guardar centro
					</b-button>
					<button
					type="button"
					class="btn btn-link ml-auto"
					@click="showAddModal = false"
					>
					Cerrar
					</button>
			</template>
    	</modal>
	</div>
</template>

<script>
	import api from '@/api/services';
	import { Select, Option } from 'element-ui';
	
	import { mapGetters } from 'vuex';
	//import PieChart2 from './PieChart2';
	import StackedBarChartConfig from "./StackedBarChartConfig.vue";
	import PuertaVending from '../Components/PuertaVending.vue';
	import Vue from 'vue';

	export default {
		name: 'form-components',
		components: {
			PuertaVending,
			//PieChart2,
			StackedBarChartConfig,
			[Select.name]: Select,
			[Option.name]: Option,
		},
		data() {
			return {
				searchFilter: '',
				puertas:[],
				puertas2:[],
				listaPuertas1:[],
				listaPuertas2: [],
				selectedEstado: 'todas',
				fields: [
					{ key: 'nombre', sortable: false },
					{ key: 'concepto', sortable: false },
					{ key: 'fecha', sortable: false },
					// { key: 'hora', sortable: false },
					{ key: 'metodo', sortable: false },
					{ key: 'coste', sortable: false },
				],
				selectTienda: [
					{ label: 'tienda 1', value: 'tienda 1' },
					{ label: 'tienda 2', value: 'tienda 2' },
				],
				items: [],
				fecha: null,
				fecha2: null,
				busqueda: false,
				cliente: '',
				concepto: '',
				gestoria: false,
				mostrar1: [],
				mostrar2: false,
				sportCenterRequest: {},
				doorRequest: {},
				doorRequestList: [],
				tubeList: [],
				statusList: [],
				vendingList: [],
				serialNumber: null,
        		showAddModal: false,
				doorFields: [
				{ key: 'doorNumber', label: '#', width: '20px' },
				{ key: 'tpStatusId', label: 'Estado' },
				{ key: 'tpTubeId', label: 'Marca' },
				{ key: 'acciones', label: ' ' }
				],
			};
		},
		computed: {
			...mapGetters({
				authUser: 'authUser',
			}),
			firstGroup() {
				const filteredGroups = {};
				for (const vending of this.vendingList) {
					filteredGroups[vending] = vending.doorDtoList.slice(0, 20).filter(item => {
						if (this.selectedEstado !== 'todas') {
							return item.tpStatusDto.name == this.selectedEstado;
						}
						return true;
					});
				}
				return filteredGroups;
			},
			secondGroup() {
				const filteredGroups = {};
				for (const vending of this.vendingList) {
					filteredGroups[vending] = vending.doorDtoList.slice(20).filter(item => {
						if (this.selectedEstado !== 'todas') {
							return item.tpStatusDto.name == this.selectedEstado;
						}
						return true;
					});
				}
				return filteredGroups;
    		},
		},
		async mounted() {
			await this.getSportCenter();
			this.mostrar1 = Array(this.items.length).fill(false);
		},
		watch: {
			searchFilter () {
				this.items = this.searchFilter === '' ? this.itemsFiltro : this.itemsFiltro.filter((it) => it.sportCenterName.toLowerCase().includes(this.searchFilter.toLowerCase()))
			}
		},
		methods: {
			getNombreEstado(id) {
				const status = this.statusList.find(item => item.id === id);
				return status ? status.name : '';
			},

			getNombreMarca(id) {
				const marca = this.tubeList.find(item => item.id === id);
				return marca ? marca.name : '';
			},

			addPuerta(data) {
				let initDoorNumber = 1;
				let endDoorNumber = 20;
				if (this.doorRequestList.length == 20) {
					initDoorNumber = 21;
					endDoorNumber = 40;
				}
				let newDato = JSON.parse(JSON.stringify(data));
				for (let doorN = initDoorNumber; doorN <= endDoorNumber; doorN++) {
					newDato.doorNumber = doorN;
					this.doorRequestList.push(newDato);
					newDato = JSON.parse(JSON.stringify(newDato));
				}
				
				SwalMixin('Se ha creado un grupo de 20 puertas.', 'success')
			},
			
			editRow(row) {
				this.$set(row.item, 'editable', true);
			},

			saveRow(row) {
				this.$set(row.item, 'editable', false);
				
				const editItem = {
					id: row.item.id,
					doorNumber: row.item.doorNumber,
					tpStatusId: row.item.tpStatusId,
					tpTubeId: row.item.tpTubeId,
				}
				this.updateEditedItems(editItem);
			},

			updateEditedItems(editItem) {
				
				const existingIndex = this.doorRequestList.findIndex(item => item.doorNumber === editItem.doorNumber);

				if (existingIndex >= 0) {
					this.doorRequestList[existingIndex] = editItem;
				} else {
					this.doorRequestList.push(editItem);
				}
			},

			cancelEditRow(row) {
				this.$set(row.item, 'editable', false);
			},

			async addItem() {
				this.sportCenterRequest = {
				sportCenterName: "",
				address: "",
				postalCode: "",
				telephone: "",
				city: "",
				}
				this.serialNumber = ""
				this.doorRequest = {
					doorNumber: 0,
				    tpStatusId: 1,
				    tpTubeId: 1,
				}
				this.doorRequestList = []	
				await this.getResources();
				this.showAddModal = true
			},
			async saveEvent() 
			{
				if (
				this.sportCenterRequest.sportCenterName == "" ||
				this.sportCenterRequest.address == "" ||
				this.sportCenterRequest.postalCode == "" ||
				this.sportCenterRequest.telephone == "" ||
				this.sportCenterRequest.city == "" ||
				this.serialNumber == ""
				) 
				{
					SwalMixin('Valores inválidos. Favor corregir', 'error')
					return
				}
				if (this.doorRequestList.length < 20) {
					SwalMixin('Debe incluir al menos 20 puertas exactas. Favor corregir', 'error')
					return
				}
				if (this.doorRequestList.length > 20 && this.doorRequestList.length < 40) {
					SwalMixin('Debe incluir 20 o 40 puertas exactas. Favor corregir', 'error')
					return
				}
				const newData = {
					sportCenterRequest: this.sportCenterRequest,
					doorRequestList: this.doorRequestList,
					serialNumber: this.serialNumber
				}
				const response = await api.setSportCenter(this.authUser, newData)
				/*console.log(response.ok)
				console.log(response.status)
				if (response == undefined || response == null || response != 'Realizado')
				{
					const errorMessage = "Error al actualizar datos."
					SwalMixin(errorMessage, 'error')
					await this.getSportCenter();
					return
				} */  
				SwalMixin('El Centro ha sido agregado con éxito', 'success')
				await this.getSportCenter();
				this.showAddModal = false
				this.sportCenterRequest = {}
				this.doorRequestList = []
				this.serialNumber = ""
			},   

			async viewSportCenter(id, index) {
				this.vendingList = [];
				const mostrarValue = this.mostrar1[index];
				this.mostrar1 = Array(this.items.length).fill(false);
				await this.showSportCenter(id)
				if (this.vendingList.length == 0) {
					SwalMixin('Noy informacion disponible para este centro', 'warning');
					return;
				}
				//this.mostrar1[index] = !this.mostrar1[index];
				//Vue.set(this.mostrar1, index, !this.mostrar1[index]);
				this.$set(this.mostrar1, index, !mostrarValue);
				await this.getResources();
			},

			async getResources() {
				//Llammar apis
				let resp = await api.getEstado(this.authUser)
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.statusList = resp
				} 

				resp = await api.getMarca(this.authUser) 
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.tubeList = resp
				} 
			},

			async getSportCenter()
			{
				const resp = await api.getSportCenter(this.authUser)
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.items = resp
					this.itemsFiltro = resp
				}  
			},
			async showSportCenter(id) {
				const resp = await api.showSportCenter(this.authUser, id)
				if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
					this.vendingList = resp
				}  
			},

			filtrarPuertas() {
				if (this.selectedEstado === 'todas') {
					this.listaPuertas1 = this.puertas;
				} else {
					this.listaPuertas1 = this.puertas.filter(
						puerta => puerta.tpStatusId.name === this.selectedEstado
					);
				}
				/*this.listaPuertas2 = [];
				if (this.selectedEstado === 'todas') {
					this.listaPuertas2 = this.puertas2;
				} else {
					this.listaPuertas2 = this.puertas2.filter(
						puerta => puerta.state === this.selectedEstado
					);
				}*/
			},
			modificaLinea(row) {
				this.items.forEach(item => {
					// console.log(item)
				});
			},
			resetFilters() {
				this.fecha = null;
				this.fecha2 = null;
				this.cliente = '';
				this.concepto = '';
			},
			async bsqFilter() {
				const res = await api.getTickets(this.authUser, this.fecha, this.fecha2);
				this.items = [];
				res.forEach(item => {
					const fch = item.date.split('T');
					item.coste = item.total;
					item.concepto = item.test;
					item.fecha = fch[0];
					item.nombre = item.customer;
					if (item.tpPaymentId === 1) {
						item.metodo = 'Tarjeta';
					} else if (item.tpPaymentId === 2) {
						item.metodo = 'Bizum';
					} else if (item.tpPaymentId === 3) {
						item.metodo = 'Metálico';
					}
				});
				let filtro = [];
				res.forEach(item => {
					let criba = false;
					if (this.cliente !== '' && this.cliente !== item.nombre) {
						criba = true;
					}
					if (this.concepto !== '' && this.concepto !== item.concepto) {
						criba = true;
					}
					if (!criba) {
						filtro.push(item);
					}
				});
				this.items = filtro;
			},
			filtro() {
				let splitDesde = null;
				let splitHasta = null;
				if (this.fecha !== null) {
					splitDesde = this.fecha.split('-');
				}
				if (this.fecha2 !== null) {
					splitHasta = this.fecha2.split('-');
				}
				let arrayFiltro = [];
				this.items = JSON.parse(localStorage.getItem('tickets') || '[]');
				this.items.map(item => {
					let ok1 = false;
					let ok2 = false;
					item.correo = false;
					const splitFecha = item.fecha.split('-');
					if (splitDesde !== null) {
						if (
							parseInt(splitFecha[0]) >= parseInt(splitDesde[0]) &&
							parseInt(splitFecha[1]) >= parseInt(splitDesde[1]) &&
							parseInt(splitFecha[2]) >= parseInt(splitDesde[2])
						) {
							ok1 = true;
						}
					} else {
						ok1 = true;
					}
					if (splitHasta !== null) {
						if (
							parseInt(splitFecha[0]) <= parseInt(splitHasta[0]) &&
							parseInt(splitFecha[1]) <= parseInt(splitHasta[1]) &&
							parseInt(splitFecha[2]) <= parseInt(splitHasta[2])
						) {
							ok2 = true;
						}
					} else {
						ok2 = true;
					}
					if (ok1 == true && ok2 == true) {
						arrayFiltro.push(item);
					}
				});
				this.items = arrayFiltro;
			},

			test(item) {
				// console.log('Llego??', item)
				item.correo === undefined || item.correo === false
					? (item.correo = true)
					: (item.correo = false);
			},

			filter() {
				this.busqueda ? (this.busqueda = false) : (this.busqueda = true);
			},

			exportar(metodo) {
				if (metodo === 'CSV') {
					// Variable to store the final csv data
					var csv_data = [];

					// Get each row data
					var rows = document.getElementsByTagName('tr');
					for (var i = 0; i < rows.length; i++) {
						// Get each column data
						var cols = rows[i].querySelectorAll('td,th');

						// Stores each csv row data
						var csvrow = [];
						for (var j = 0; j < cols.length; j++) {
							// console.log(cols[j].innerHTML)
							if (j === cols.length - 1 && i !== 0) {
								const eliminaMinus = cols[j].textContent.split(' ');
								csvrow.push(eliminaMinus[0]);
							} else {
								csvrow.push(cols[j].textContent);
							}
						}

						// Combine each column value with comma
						csv_data.push(csvrow.join(';'));
					}

					// Combine each row data with new line character
					csv_data = csv_data.join('\n');

					// Call this function to download csv file
					var BOM = '\uFEFF';
					window.open('data:application/vnd.ms-excel,' + encodeURI(BOM + csv_data));
				} else {
					var mywindow = window.open('', 'PRINT', '');
					mywindow.document.write('<table>');
					mywindow.document.write('<tr>');
					mywindow.document.write(
						document.getElementsByClassName('table')[0].childNodes[2].innerHTML
					);
					mywindow.document.write('</tr>');
					document
						.getElementsByClassName('table')[0]
						.childNodes[3].childNodes.forEach(item => {
							if (item.nodeName === 'TR') {
								mywindow.document.write('<tr>');

								let array = item.childNodes;
								// console.log(array)
								for (let i = 0; i < array.length; i++) {
									mywindow.document.write('<td>');
									mywindow.document.write(array[i].textContent);
									mywindow.document.write('</td>');
								}
								mywindow.document.write('</tr>');
							}
						});
					// mywindow.document.write(document.getElementsByClassName('table')[0].childNodes[3].innerHTML)
					mywindow.document.write('</table>');
					mywindow.print();
				}
			},
		},
	};
</script>

<style>
	.main-content {
		background-color: #f4f6fa;
	}
	.col-vending {
		max-width: 1400px;
		margin: 0 auto;
	}
	.estado__puertas {
		background-color: #fff;
	}
	.estado__puertas-card {
		background-color: #f5f5f5;
		border-radius: 0 0 12px 12px;
		padding-bottom: 40px;
	}
	.estado__puertas--title {
		background-color: #003d52;
		color: white;
		height: 50px;
		border-radius: 12px 12px 0 0;
		padding: 15px 40px;
		font-weight: 600;
		font-size: 15px;
	}
	.estado__puertas--stats {
		background-color: #8ba7b0;
		height: 50px;
		margin-bottom: 20px;
		padding-top: 14px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
	.estado__puertas--stats2,
	.estado__puertas--stats3 {
		background-color: #fff;
		height: 150px;
		margin: 50px auto 15px auto;
		border: 1px solid #dadada;
		color: #444;
		display: flex;
		justify-content: flex-start;
		gap: 30px;
		border-radius: 12px !important;
		box-shadow: none;
	}
	.estado__puertas--stats2 {
		height: 200px;
	}
	.estado__puertas--stats3 {
		margin: 0;
	}
	.estado__puertas--stats2 h5,
	.estado__puertas--stats3 h5 {
		font-size: 20px;
		margin: -10px 0 10px;
	}
	.estado__puertas--stats p {
		display: inline-block;
		background-color: white;
		font-size: 14px;
		font-weight: 600;
		padding: 3px 9px 2px 9px;
	}
	.estado__puertas--stats2 p,
	.estado__puertas--stats3 p {
		display: inline-block;
		background-color: #f5f5f5;
		font-size: 14px;
		font-weight: 600;
		padding: 3px 9px 2px 9px;
	}
	.estado__puertas--stats.stats1 {
		color: #de6d6c;
	}
	.estado__puertas--stats.stats2 {
		color: #f1e30f;
	}
	.estado__puertas--stats.stats3 {
		color: #f6c577;
	}
	.estado__puertas--stats.stats4 {
		color: #80e280;
	}
	.stats1 span,
	.stats2 span,
	.stats3 span,
	.stats4 span {
		font-size: 11px;
		font-weight: 600;
		text-transform: uppercase;
	}
	.estado__puertas select {
		background-color: #e6ebff;
		border-radius: 20px;
		padding: 12px 15px !important;
		width: 230px;
		font-size: 14px;
		color: #444;
		cursor: pointer;
	}
	.estado__puertas select,
	.estado__puertas select:focus,
	.estado__puertas select:focus-visible,
	.estado__puertas select:focus-within,
	.estado__puertas select:active,
	.estado__puertas select:link,
	.estado__puertas select:hover {
		border: none !important;
	}
	.barra-invert {
		border-right: 2px solid #c9c9c9;
	}
	.puertas1,
	.puertas2 {
		padding: 0 72px !important;
		min-height: 300px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 40px;
		/*justify-content: space-between;*/
		align-content: flex-start;
	}
	.last-cards {
		margin: 0 70px !important;
		padding: 0 15px !important;
	}

	@media (max-width: 1440px) {
		.col-vending {
			width: 1100px;
		}
		.stats1,
		.stats2,
		.stats3,
		.stats4 {
			font-size: 11px !important;
			padding: 3px 7px 2px 7px !important;
		}
		.stats1 span,
		.stats2 span,
		.stats3 span,
		.stats4 span {
			font-size: 9px;
		}
		.stats1 img,
		.stats2 img,
		.stats3 img,
		.stats4 img {
			width: 11px;
		}
		.estado__puertas--stats2 .stats1,
		.estado__puertas--stats2 .stats2,
		.estado__puertas--stats2 .stats3 {
			margin-right: 10px !important;
		}
		.puertas1 {
			padding: 0 25px 0 35px !important;
			gap: 25px !important;
		}
		.puertas2 {
			padding: 0 35px 0 25px !important;
			gap: 25px !important;
		}
		.last-cards {
			margin: 0 50px !important;
			padding: 0 !important;
		}
	}
	@media (max-width: 1366px) {
		.last-cards {
			margin: 0 40px !important;
		}
	}
	@media (max-width: 1280px) {
		.puertas1 {
			padding: 0 20px 0 30px !important;
			gap: 25px !important;
		}
		.puertas2 {
			padding: 0 30px 0 20px !important;
			gap: 25px !important;
		}
		.estado__puertas--stats2 .card-body,
		.estado__puertas--stats3 .card-body {
			padding: 30px 20px;
		}
		.last-cards {
			margin: 0 30px !important;
		}
	}

	@media (max-width: 600px) {
		.estado__puertas--stats2{
			height: 200px;
		}
		.estado__puertas--stats3 {
			height: 150px;
		}
	}

	@media (max-width: 767px) {
		.table-responsive table thead,
		.table-responsive table tbody,
		.table-responsive table th,
		.table-responsive table td,
		.table-responsive table tr {
			display: block;
		}
		
		.table-responsive table thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		.table-responsive table tbody tr {
			border-bottom: 1px solid #ccc;
		}
		
		.table-responsive table td,
		.table-responsive table th {
			display: block;
			width: 100%;
			clear: left;
			text-align: left;
			padding-left: 10px;
		}
	}

	th {
		font-weight: bold !important;
		/* color:#fff !important; */
		color: #c1c1c1 !important;
		font-size: 0.8rem !important;
		/* background-color:#37304a !important; */
		/* background-color:#00dfeb  !important; */
	}
	td {
		font-size: 1rem !important;
	}

	thead {
		box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
	}

	.table-striped > tbody > tr:nth-child(odd) > td,
	.table-striped > tbody > tr:nth-child(odd) > th {
		/* background-color: #fff; */
		border: none;
	}
	.table-striped > tbody > tr:nth-child(even) > td,
	.table-striped > tbody > tr:nth-child(even) > th {
		/* background-color: #f5f5f5; */
		border: none;
	}
	.table-striped > thead > tr > th {
		border: none;
		/* background-color: #f5f5f5; */
	}
	.mb20x {
		margin-bottom: 20px;
	}
	.mt20x {
		margin-top: 20px;
	}
	.mt35x {
		margin-top: 35px;
	}
	.btnCorreo {
		padding: 10px !important;
		font-size: 12px !important;
	}

	.cajaCorreo {
		height: 26px;
		width: 80px;
		margin-right: 0.5rem !important;
	}

	.metodo {
		padding: 0.2rem 0.4rem;
		font-size: 87.5%;
		color: #000;
		background-color: #ccf1ee;
		border-radius: 0.2rem;
		text-align: center;
	}

	.metodo2 {
		padding: 0.2rem 0.4rem;
		font-size: 87.5%;
		color: #000;
		background-color: #dae6f5;
		border-radius: 0.2rem;
		text-align: center;
	}

	.concepto {
		width: 10px;
		height: 10px;
		background: red;
		border-radius: 50%;
		display: inline-block;
	}
	.concepto2 {
		background: #00e0ec;
		border: 0px;
		position: absolute;
		right: 5%;
		border-radius: 50%;
		padding: 0px 5px;
	}

	.bgTransparent {
		background: transparent;
	}

	.btn:not(:selected):not(.selected) {
		cursor: none !important;
	}

	#bv-modal-example___BV_modal_header_ {
		align-items: center;
		padding-bottom: 0px;
	}

	.btnBusqueda {
		background: #e5fcfd;
		border: none;
		color: #7096ab;
		padding: 5px;
		font-size: 12px;
		margin-bottom: 20px;
	}

	.mxW13x {
		max-width: 13px;
	}

	.mxW25x {
		max-width: 25px;
	}

	.W20x {
		width: 20px;
	}

	.dropdown-toggle {
		background: #e5fcfd;
		padding: 5px;
	}

	.w200X {
		min-width: 200px;
	}

	.bgPrimary {
		background: #003d52;
	}

	.borderAzul {
		border: 1px solid #003d52;
		border-radius: 5px;
	}

	.blanco {
		color: #fff;
	}

	.p15x {
		padding: 15px;
	}

	.borderPrimary {
		border-left: 1px solid #003d52;
		border-right: 1px solid #003d52;
		border-bottom: 1px solid #003d52;
	}

	.bgGrey {
		background: #f4f6fa;
	}

	.fRight {
		float: right;
	}

	.bold {
		font-weight: bold;
	}

	.inputLogin {
		border: none;
		padding: 15px;
		background-color: #f4f6fa !important;
	}

	.borderGrey {
		border-bottom: 1px solid #f1f1f1;
	}

	.bg-grey > button {
		background: #f4f6fa;
		margin-left: 10px;
	}

	.radius {
		border-radius: 5px;
	}

	.iconBuscador {
		position: absolute;
		right: 10px;
		top: 10px;
		max-width: 20px;
	}

	.inlineBlock {
		display: inline-block;
	}

	.relative {
		position: relative;
	}

	.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
	}

	.mr-0::before {
		margin-right: 0px !important;
	}

	.mxW15x{
		max-width: 15px;
	}

	.circle {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 5px;
	}
	
	.btnAddCenter {
		border: 1px solid #003d52;
		border-radius: 5px;
		background: #003d52;
		color: #fff;
		font-weight: bold;
		border-radius: 5px;
		padding: 10px;
		max-width: 200px;
		height: 45px;
		font-size: 87.5%;
	}

	@media (max-width: 400px) {
		.table-responsive .table.stacked th,
		.table-responsive .table.stacked td {
			display: block;
			width: 100%;
	}
	
}
</style>
