<template>
  <div>
    <base-header class="pb-6">
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="12">
          <div class="card-wrapper">
            <!-- Input groups -->
						<card style="padding-bottom: 5px">
              <div class="ico-sidebar ico_dashboard inlineBlock iconVending mr-0"></div>
              Dashboard /
              <i class="ni ni-tag"></i>
              Marcas
						</card>
            <card>
                <b-row>
                  <b-col cols="6" class="text-left">
                    <h3 class="mb20x">Marcas</h3>
                  </b-col>  
                  <b-col cols="6" class="text-right">
                    <base-button
                      @click.prevent="addItem"
                      type="primary"
                      native-type="submit"
                      >Nuevo</base-button
                    >
                  </b-col>
                </b-row>   
                
            
              <b-table :items="items" :fields="fields" striped responsive="sm">  
                <template #cell(urlImage)="row">
                  <img :src="row.item.urlImage" class="mxW13x" alt="">
                </template>  
                <template #cell(acciones)="row">
                  <b-button variant="secondary" size="sm" @click="confirmDeleteItem(row.item)"><img src="../../../public/borrar.png" class="mxW15x" title="Eliminar"></b-button>
                </template>
              </b-table>
            </card>
          </div>
        </b-col>
      </b-row>
    </b-container>

   

    <!-- add marca -->
    <modal :show.sync="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" enctype="multipart/form-data">
        <b-tabs content-class="mt-3">           
          <b-tab title="Agregar marca">
            <base-input
                v-model="name"
                label="Nombre"
                prepend-icon="fas fa-user"
                placeholder="Nombre"
              ></base-input>
            <input type="file" accept=".svg"  ref="imgFile">
          </b-tab>
        </b-tabs>      
      </form>

      <template  v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="save"
        >
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Cerrar
        </button>
      </template>

    </modal>

    <modal :show="showDeleteItem" modal-classes="modal-secondary">
      <div class="flex align-items-center justify-content-center">
        <h3>Confirmar</h3>
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>¿Estas seguro que deseas eliminar la Marca?</span>
      </div>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="deleteItem"
        >
          Sí
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="showDeleteItem=false"
        >
          No
        </button>
      </template>
    </modal>

  </div>
</template>

<script>
  import api from '@/api/services'
  import { Select, Option } from 'element-ui'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'form-components',
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         fields: [
         { key: 'name', label: 'Nombre' },
         { key: 'urlImage', label: 'Imagen' },
         { key: 'acciones', label: ' ' }
        ],
        items: [],
        busqueda: false,
        filterData: {
          name: null,
        },

        name: null,
        mark: {},
        item: {},
        showAddModal: false,
        showDeleteItem: false,
      }
    },

    computed: {
      ...mapGetters({
        appName: 'appName',
        authUser: 'authUser'
      })
    },

    async mounted () {
      this.getMarcas()
    },

    methods: {
      ...mapActions({
        setAppName: 'setAppName'
      }),
    
      async getMarcas()
      {
        const resp = await api.getMarcas(this.authUser)
        if (typeof(resp.message) == 'object' || Array.isArray(resp) == true) {  
          this.items = resp
          this.itemsFiltro = resp
        }  
      },

      addItem() {
        this.name = ''
        this.showAddModal = true
      },


      confirmDeleteItem (item) {
        this.item = item
        this.showDeleteItem = true
      },

      async save(event) 
      {
        event.preventDefault();
        const imgFile = this.$refs.imgFile.files[0];
       // console.log(imgFile)
        if (imgFile !== undefined) {
          const errorMesg = this.validacion() 
          if (errorMesg !== '')
          {
            SwalMixin(errorMesg, 'error')
            return
          }
          const allowedTypes = ['image/svg+xml', 'image/png'];
          if (!allowedTypes.includes(imgFile.type)) {
            SwalMixin('Por favor, selecciona un archivo SVG o PNG válido.', 'error')
            return;
          }
          const formData = new FormData();
          formData.append('file', imgFile);
          formData.append('name', this.name);
          await api.setMarca(this.authUser, formData)
        }  
        SwalMixin('Marca agregado con éxito', 'success')
        this.getMarcas()
        this.showAddModal = false
      },

      async deleteItem () {
        const response = await api.deleteMarca(this.authUser, this.item.id)
        if (response == undefined || response == null)
        {
            const errorMessage = "Error al actualizar datos."
            SwalMixin(errorMessage, 'error')
            return
        }  
        this.showDeleteItem = false 
        SwalMixin('Marca eliminado', 'success')
        await this.getMarcas()
      },

      async bsqFilter () {
        this.items = []
        this.itemsFiltro.forEach((it) => {
          let filtro = true
          if (this.filterData.name !== null && this.filterData.name !== '') {
            if (!it.name.includes(this.filterData.name)) {
              filtro = false
            }
          }

          if (filtro) {
            this.items.push(it)
          }
        
        })
      },

      resetFilters() {
        this.filterData = {
          name: null,
        }
        this.getMarcas()
      },

      filter () {
        this.busqueda ? this.busqueda = false : this.busqueda = true
      },

      validacion () {
        let retorno = ''
        if (this.name === null || this.name === '') { retorno = 'Debes introducir un nombre válido' }
        return retorno
      }
    }
  }
</script>
<style>
th{
  font-weight: bold !important;
  color:#000 !important;
  font-size:0.8rem !important;
  font-weight: bold;
  /* background-color:#11d5e3 !important; */
}
td {
    font-size: 1rem !important;
}

thead{
  box-shadow: 2px 0px 25px 4px rgb(105 95 151 / 6%) !important;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
      .table-striped>tbody>tr:nth-child(odd)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>tbody>tr:nth-child(even)>td, 
      .table-striped>tbody>tr:nth-child(even)>th {
       /* background-color: #f5f5f5; */
      }
      .table-striped>thead>tr>th {
         /* background-color: #f6f9fc; */
      }

.mb20x{
    margin-bottom:20px;
}
.flexCenter {
  display: flex;
  align-items: center;
}

.btnBusqueda{
  background:#e5fcfd;
  border:none;
  color:#7096ab;
  padding: 5px;
  font-size: 12px;
  margin-bottom:20px;
}

.mxW13x{
  max-width: 21px;
}

.mxW25x{
  max-width: 25px;
}

.W20x{
  width:20px;
}

.dropdown-toggle {
  background: #e5fcfd;
  padding: 5px;
}

.w200X{
  min-width: 200px;
}

.p5x{
  padding:5px;
}

.inlineBlock {
		display: inline-block;
}

.iconVending::before {
		background-size: 15px;
		background-position-y: 7px;
}
  
.mxW15x{
  max-width: 15px;
}

.btnFilter{
  background: #003d52;
  border:none;
  color: #fff;
  padding: 5px;
  font-size:  87.5%;
  margin-bottom:20px;
  height: 40px;
  width: 150px
}
</style>